import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {ExampleKeywordButton} from './ExampleKeywordButton'

export const InfoCard = () => {
  const {t} = useTranslation()
  // const history = useHistory();

  return (
    <div className="info-card ">
      <p className="text-sm font-light">
        <Trans>
          {t(
            'Our experienced search team will create a list of <br/>high-volume'
          ) + ' '}
        </Trans>

        {t('keyword terms including your branded terms. ')}

        {t(
          'These keywords will be monitored upon approval of your free trial account. See an '
        )}
        <ExampleKeywordButton />
        {t(
          ' You will be able to edit, remove, add keyword terms in your own dashboard.'
        )}
      </p>
    </div>
  )
}
