import {request} from 'rest/helpers'

export class Services {
  static opm_offers = async params => await request.get('/opm-offers ', params)
  static offers = async params =>
    await request.get('/secretone/offers/', {...params, only_base: true}, false)
  static GuardCountries = async params =>
    await request.get('/secretone/countries/', params, false)
  static timezones = async params =>
    await request.get('/secretone/timezones/', params, false)

  static NetworkCategories = async params =>
    await request.get('/ghost/categories/', params, false)
  static NetworkCountries = async params =>
    await request.get('/ghost/countries/', params, false)
  static NetworkPublisherTypes = async params =>
    await request.get('/ghost/publishers/types/', params, false)

  static readKeywords = async ({params}) =>
    await request.get('/secretone/keywords', params, false)
}
