import React from 'react'
import {Trans} from 'react-i18next'
import {Link} from 'react-router-dom'

export const ButtonGoHome = ({className}) => (
  <Link
    className={`text-sm inline-flex items-center
        border-transparent font-medium rounded-md 
        focus:outline-none 
        ${className}`}
    to="/"
  >
    <i className="icon-left-dir" />
    <Trans>Go Home</Trans>
  </Link>
)
