import {inject, observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'

import * as Page from 'pages'
import {GuestRoute} from './GuestRoute'
import {PrivateRoute} from './PrivateRoute'
import {AuthLoader, qs} from '@evet/library'
import {LayoutGuestFullGray} from '../layouts'

export const Routing = inject('AuthStore')(
  observer(props => {
    const {isCorrectEnvironment, authenticated, me, handleAuth, signOut} =
      props.AuthStore

    useEffect(() => {
      const {from} = qs.parse(window.location.search)
      if (
        !['/dashboard'].includes(window.location.pathname) &&
        authenticated &&
        me
      ) {
        window.location = '/dashboard'
      }

      if (!['/logout', '/pending'].includes(window.location.pathname)) {
        handleAuth().then(res =>
          res ? (window.location = from || '/dashboard') : false
        )
      }
      if (
        !isCorrectEnvironment &&
        !['/login', '/logout', '/pending'].includes(window.location.pathname)
      ) {
        signOut(window.location + '&_incorrect_env').then()
      }

      // eslint-disable-next-line
    }, [])

    return authenticated && !me ? (
      <AuthLoader />
    ) : (
      <BrowserRouter>
        <Routes>
          <Route element={<GuestRoute layout={LayoutGuestFullGray} />} path="/">
            <Route element={<Page.SignIn />} index />
            <Route element={<Page.SignIn />} path="login" />
            <Route element={<Page.SignOut />} path="logout" />
            <Route element={<Page.Reset />} path="reset" />
            <Route element={<Page.NewPassword />} path="newpassword/:token" />
            <Route element={<Page.Verify />} path="verify/:token" />
            <Route element={<Page.Activate />} path="/invite/:token" />
            <Route element={<Page.PrivacyPolicy />} path="privacy-policy" />
            <Route
              element={<Page.TermsOfServices />}
              path="terms-of-services"
            />
          </Route>

          <Route element={<GuestRoute />} path="/">
            <Route element={<Page.SignUp />} path="register">
              <Route index element={<Page.Projects />} />
              <Route path="guard" element={<Page.Guard />} />
              <Route path="guard/plan" element={<Page.GuardStep2 />} />
              <Route path="guard/payment" element={<Page.GuardStep3 />} />
              <Route path="guard/done" element={<Page.GuardDone />} />
              <Route
                path="network/publisher"
                element={<Page.NetworkPublisher />}
              />
              <Route
                path="network/publisher/promotion"
                element={<Page.NetworkPublisherStep2 />}
              />
              <Route
                path="network/publisher/done"
                element={<Page.NetworkPublisherDone />}
              />
              <Route
                path="network/advertiser"
                element={<Page.NetworkAdvertiser />}
              />
              <Route
                path="network/advertiser/promotion"
                element={<Page.NetworkAdvertiserStep2 />}
              />
              <Route
                path="network/advertiser/done"
                element={<Page.NetworkAdvertiserDone />}
              />
            </Route>
          </Route>

          <Route
            path="dashboard"
            element={<PrivateRoute authenticated={authenticated} />}
          >
            <Route index element={<Page.Dashboard />} />
          </Route>

          <Route element={<GuestRoute layout={LayoutGuestFullGray} />}>
            <Route path="*" element={<Page.NoMatch />} />
          </Route>
        </Routes>
      </BrowserRouter>
    )
  })
)
