import {action, makeObservable, observable} from 'mobx'

export class UIStore {
  height = window.innerHeight
  width = window.innerWidth
  alerts = new observable.map()
  modal = {}
  side = {}
  dropdown = {
    Menu: false,
    UserMenu: false,
    URLSwitch: false,
    ReportsMenu: false,
    NotificationMenu: false,
  }

  currencyName = 'USD'
  currency = '$'
  currencyRatio = 1

  currencies = [
    {name: 'USD', ratio: 1, sign: '$'},
    {name: 'EUR', ratio: 0.88, sign: '€'},
    {name: 'TRY', ratio: 6.86, sign: 'TL'},
  ]

  constructor(Stores) {
    this.stores = Stores

    makeObservable(this, {
      height: observable,
      width: observable,
      alerts: observable.deep,
      modal: observable,
      side: observable,
      dropdown: observable,
      currencyName: observable,
      currency: observable,
      currencyRatio: observable,
      currencies: observable,

      setHeight: action,
      setWidth: action,
      updateWindowSize: action,
      toggleDropDown: action,
      modalOpen: action,
      modalClose: action,
      changeCurrency: action,
    })
  }

  setHeight = height => (this.height = height)

  setWidth = width => (this.width = width)

  updateWindowSize = (width, height) => {
    this.width = width
    this.height = height
  }

  toggleDropDown = (menu, status = true) => {
    if (status)
      Object.keys(this.dropdown).forEach(i =>
        i !== menu ? (this.dropdown[i] = false) : null
      )
    this.dropdown[menu] = status && !this.dropdown[menu]
  }

  modalOpen = ({id, title, content, actions, show, type, onClose, size}) => {
    show = show !== false
    type = type || 'normal'
    this.modal = {id, title, content, actions, show, type, onClose, size}
  }

  modalClose = () => {
    if (this.modal.onClose) this.modal.onClose()
    this.modal = {
      id: '',
      title: '',
      content: '',
      action: '',
      show: false,
      type: '',
      onClose: null,
    }
  }

  changeCurrency = name => {
    const currency = this.currencies.find(i => i.name === name)
    this.currency = currency.sign
    this.currencyRatio = currency.ratio
    this.currencyName = name
  }
}
