import {action, computed, makeObservable, observable} from 'mobx'
import {CommonServices as Service} from '../services'

export default class CountryStore {
  state = 'pending'
  _list = []

  constructor(Stores) {
    this.stores = Stores

    makeObservable(this, {
      state: observable,
      _list: observable,

      list: computed,

      read: action,

      fetchSuccess: action.bound,
      fetchError: action.bound,
    })
  }

  async read() {
    if (this.state === 'listing' || this.state === 'done') return
    this._list = []
    this.state = 'listing'
    await Service.countries().then(this.fetchSuccess, this.fetchError)
  }

  fetchSuccess = res => {
    this._list = res.items
    this.state = 'done'
  }

  fetchError = error => {
    this.state = 'error'
    return this.stores.SystemMessageStore.handleError(error)
  }

  get list() {
    return this._list.map(i => ({
      ...i,
      label: i.country_cd,
      value: i.country_id,
    }))
  }
}
