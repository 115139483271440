import React, {useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {StepWrapper} from './StepWrapper'

export const Projects = () => {
  // FIXME production control
  const pass = process.env.NODE_ENV === 'production'
  const navigate = useNavigate()

  useEffect(() => {
    if (pass) {
      navigate('/register/guard')
    }
  })
  const {t} = useTranslation()

  return pass ? null : (
    <StepWrapper
      page={t('REGISTER')}
      title={t('Select a Project to Register')}
      description={t(
        'Evet, it offers you multiple platforms. Which platform do you want to sign up for?'
      )}
    >
      <div className="w-full bg-gray-100 pb-10 flex justify-center items-center text-gray-700 text-sm text-center">
        <Link className="" to={'/register/guard'}>
          <i
            className="icon-shield text-3xl rounded-md bg-gray-200 text-primary flex justify-center items-center w-20 h-20 m-2
                   hover:bg-primary-hover-negative hover:text-primary-hover"
          />
          {t('GUARD')}
        </Link>
        <Link className="" to={'/register/network/publisher'}>
          <i
            className="icon-network text-3xl rounded-md bg-gray-200 text-primary flex justify-center items-center w-20 h-20 m-2
                   hover:bg-primary-hover-negative hover:text-primary-hover"
          />
          {t('NETWORK')}
        </Link>
      </div>
    </StepWrapper>
  )
}
