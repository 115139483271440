import {useLocation} from 'react-router-dom'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ButtonGoHome} from 'components'

export const Done = () => {
  const {t} = useTranslation()
  const {search} = useLocation()

  return search !== '?custom' ? (
    <section className="items-center flex flex-col w-full">
      <div className="items-center w-full flex flex-col font-roboto font-normal  py-24">
        <h1 className="font-semibold text-3xl text-gray-700">
          {t('Congratulations.')}
        </h1>
        <h5 className="font-light text-sm w-3/5 text-center mt-5 text-gray-600">
          {t(
            'Thanks so much for joining Evet! Verify your e-mail to finish signing up.'
          )}
        </h5>
        <p className="w-3/5 text-xs text-center mt-8 text-gray-800">
          {t('Please check your inbox for a confirmation email.') + ' '}
          {t('Click the link in the email to confirm your email address.') +
            ' '}
          {t(
            'Meanwhile, our search engine experts are reviewing your account.'
          ) + ' '}
        </p>
        <p className="text-sm w-8/12 text-center text-primary mt-5">
          {t('Your free trial starts once the account is approved.')}
        </p>

        <ButtonGoHome className="mt-4" />
      </div>
    </section>
  ) : (
    <section className="vertical center">
      <div className="container vertical center pt-100 pb-100">
        <h1 className="f-600 sz-48">{t('Thanks!')}</h1>
        <p className="f-300 sz-20 per-60 mt-20 text-center mt-10">
          {t('We’ll be in touch shortly.')}
        </p>
        <button className="btn primary mt-20">
          {t('Your free trial starts once the account is approved.')}
        </button>
      </div>
    </section>
  )
}
