import React from 'react'
import {CustomHeader, SystemMessages} from 'components'
import {HelmetHead} from './HelmetHead'
import {Layout, Version} from '@evet/library'
import packageJSON from '../../package.json'
import rightImage from '../assets/images/pattern/right.png'
import leftImage from '../assets/images/pattern/left.png'

const {dependencies, version} = packageJSON

export const LayoutGuest = ({title, description, children}) => (
  <React.Fragment>
    <HelmetHead title={title} description={description} />
    <SystemMessages />
    <Layout className="bg-gray-50 min-h-screen flex flex-col items-stretch ">
      <img
        src={leftImage}
        alt="leftGrid"
        className="hidden lg:block absolute top-36 left-0"
      />
      <img
        src={rightImage}
        alt="rightGrid"
        className="hidden sm:block absolute top-0 right-0"
      />
      <CustomHeader className="bg-gray-100" />
      {children}
      <Version
        version={version}
        title={
          'evet-library: v' +
          (dependencies['@evet/library'] || '#').split(/#/)[1]
        }
      />
    </Layout>
  </React.Fragment>
)
