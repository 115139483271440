import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import {ButtonGoHome} from 'components'
import {DoneCustom} from './DoneCustom'

export const Done = () => {
  const {t} = useTranslation()
  const {search} = useLocation()

  return (
    <section className="grow items-center flex flex-col w-full bg-gray-100">
      <div className="items-center w-full flex flex-col font-roboto font-normal  py-24">
        {search === '?custom' ? (
          <DoneCustom />
        ) : (
          <React.Fragment>
            <h1
              id="congratulations"
              className="font-semibold text-3xl text-gray-700"
            >
              {t('Congratulations.')}
            </h1>
            <h5 className="text-sm w-3/5 text-center mt-5 text-gray-600">
              {t(
                'Thanks so much for joining Evet! Verify your e-mail to finish signing up.'
              )}
            </h5>
            <p className="w-3/5 text-xs text-center mt-8 text-gray-800">
              <Trans>
                Please check your inbox for a confirmation email.
                <br />
                Click the link in the email to confirm your email address.
                Meanwhile, our search engine experts are reviewing your account.
              </Trans>
            </p>
            <p className="text-xs w-8/12 text-center text-primary mt-8">
              {t('Your free trial starts once the account is approved.')}
            </p>
          </React.Fragment>
        )}
        <ButtonGoHome className="mt-10" />
      </div>
    </section>
  )
}
