import React from 'react'
import {Link} from 'react-router-dom'
import {inject, observer} from 'mobx-react'
import {
  DropDown,
  Header,
  ProjectIcons,
  routeLocal,
  UserMenu,
} from '@evet/library'
import logo from 'assets/images/evet.svg'

export const CustomHeader = inject('AuthStore')(
  observer(props => {
    const {me, isCorrectEnvironment, signOut} = props.AuthStore

    if (!isCorrectEnvironment) {
      if (
        !['/login', '/logout', '/pending'].includes(window.location.pathname)
      ) {
        signOut(window.location + '&incorrect_env').then()
      }
    }

    return (
      <div className={`${props.className}`}>
        <Header
          className="sm:px-3 px-0 py-0.5 sm:py-1 my-2 ml-4 sm:my-10 sm:mx-14 h-12 sm:text-white text-primary/100"
          logo={
            <a
              href="https://evet.com"
              className="h-full flex item-center justify-center"
            >
              <img src={logo} alt="evet" className="" />
            </a>
          }
          right={
            !me ? (
              <React.Fragment>
                <Link
                  to="/"
                  className="flex items-center px-8 whitespace-nowrap text-gray-500 text-sm mr-2"
                >
                  Login
                </Link>
                <DropDown
                  className="bg-primary  text-white rounded-md my-1 px-4"
                  label="Sign Up For Free"
                  direction="left"
                >
                  <Link to="/register/guard">Search Compliance</Link>
                  <Link to="/register/network/publisher">
                    Affiliate Network
                  </Link>
                </DropDown>
              </React.Fragment>
            ) : (
              <UserMenu
                className="text-primary pl-3 pr-4 rounded my-1 bg-gray-200"
                label={me.name}
                direction="left"
              >
                {me.products.map(i => {
                  const url =
                    process.env.NODE_ENV === 'production'
                      ? i.url
                      : routeLocal(i)
                  return (
                    <a className="flex justify-start" key={i.id} href={url}>
                      {ProjectIcons(i.project, i.access)}
                      <span className="ml-2">
                        {i.access === 'ADMIN' ? (
                          <span>
                            {i.project}
                            <span className="italic text-sm text-gray-400">
                              {' '}
                              ({i.name})
                            </span>
                          </span>
                        ) : (
                          i.name
                        )}
                      </span>
                    </a>
                  )
                })}
                <Link to="/logout">
                  <i className="icon-logout mr-2" />
                  Logout
                </Link>
              </UserMenu>
            )
          }
          mobile={
            !me ? (
              <div className="grid bg-white text-primary border-t-2 shadow-lg">
                <Link to="/register/guard" className="p-2">
                  <i className={`${ProjectIcons('SECRETONE', 'USER')} mr-2`} />
                  Register &gt; Search Compliance
                </Link>
                <Link to="/register/network/publisher" className="p-2 border-t">
                  <i className={`${ProjectIcons('GHOST', 'USER')} mr-2`} />
                  Register &gt; Affiliate Network
                </Link>
                <Link to="/login" className="p-2 border-t border-b">
                  <i className="icon-user mr-2" />
                  Login
                </Link>
              </div>
            ) : (
              <div className="grid bg-white  border-t-2 shadow-lg">
                {me.products.map(i => {
                  const url =
                    process.env.NODE_ENV === 'production'
                      ? i.url
                      : routeLocal(i)
                  return (
                    <a
                      className="flex justify-start p-2 border-t"
                      key={i.id}
                      href={url}
                    >
                      {ProjectIcons(i.project, i.access)}
                      <span className="ml-2">
                        {i.access === 'ADMIN' ? (
                          <span>
                            {i.project}
                            <span className="italic text-sm text-gray-400">
                              {' '}
                              ({i.name})
                            </span>
                          </span>
                        ) : (
                          i.name
                        )}
                      </span>
                    </a>
                  )
                })}
                <Link
                  to="/logout"
                  className="p-2 border-t border-b bg-gray-100 text-gray-600"
                >
                  <i className="icon-logout mr-2" />
                  Logout
                </Link>
              </div>
            )
          }
        />
      </div>
    )
  })
)
