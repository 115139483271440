import {inject, observer} from 'mobx-react'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ProjectIcons, routeLocal} from '@evet/library'
import {origin} from '../../rest/helpers'

export const Dashboard = inject('AuthStore')(
  observer(props => {
    const {t} = useTranslation()
    const {me} = props.AuthStore
    return (
      <section className="grow bg-gray-50">
        <div className="h-full flex  items-center w-full max-w-full flex-col ">
          <div className="flex flex-col w-full justify-start items-center py-12">
            {/*FIXME production control*/}
            {process.env.NODE_ENV !== 'production' && (
              <h1 className="font-bold text-3xl">{t('Switch to')}</h1>
            )}
            <p
              id="evetProducts"
              className="font-normal text-sm text-primary text-center mt-3"
            >
              {t('Evet Products')}
            </p>
          </div>

          <div className="flex justify-center items-center w-full ">
            {me.products.map(i => {
              const url =
                process.env.NODE_ENV === 'production'
                  ? i.url
                  : routeLocal(i).replace('http://localhost', origin)
              return (
                <a
                  className="rounded-md bg-gray-200 text-primary flex flex-col justify-center items-center w-32 h-32 m-1.5
                   hover:bg-primary-hover-negative hover:text-primary-hover"
                  key={i.id}
                  href={url}
                >
                  <div className="mb-2 bg-transparent w-4 h-4 rounded-lg flex justify-center items-center  text-3xl">
                    {ProjectIcons(i.project, i.access)}
                  </div>
                  <span className="pt-2.5 text-center ">
                    {i.access === 'ADMIN' ? (
                      <span>
                        {i.project}
                        <br />
                        <span className="italic text-xs">({i.name})</span>
                      </span>
                    ) : (
                      i.name
                    )}
                  </span>
                </a>
              )
            })}
          </div>
        </div>
      </section>
    )
  })
)
