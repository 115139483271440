import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import {App} from './App'
//import * as serviceWorker from './serviceWorker'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({dsn: process.env.REACT_APP_SENTRY_DSN})
}

const render = Component => {
  ReactDOM.render(<Component />, document.getElementById('root'))
}

//serviceWorker.unregister()
render(App)
