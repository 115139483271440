import React from 'react'
import {Trans} from 'react-i18next'
import {Link} from 'react-router-dom'

export const Done = () => (
  <div className="flex flex-col items-center py-24 text-center">
    <h1 className="font-semibold text-3xl text-gray-900">
      <Trans>Success</Trans>
    </h1>
    <h5 className="text-base w-3/5  mt-8 text-gray-700">
      <Trans>You are now added to account.</Trans>
    </h5>
    <p className="w-3/5 mt-8 text-gray-400 text-xs">
      <Trans>You can now login your dashboard.</Trans>
    </p>

    <Link className="inline-flex justify-center items-center mt-10" to="/login">
      <i className="icon-left-dir mr-1" />
      <Trans>Go to Sign in</Trans>
    </Link>
  </div>
)
