import {Provider} from 'mobx-react'
import React from 'react'
import {I18nextProvider} from 'react-i18next'
import {Routing} from 'routing/Routing'
import i18next from 'rest/helpers/i18'
import {stores} from 'rest/stores'

import '@evet/library/dist/tailwind.css'

export const App = () => (
  <Provider {...stores}>
    <I18nextProvider i18n={i18next}>
      <Routing />
    </I18nextProvider>
  </Provider>
)
