import {inject, observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'
import {Link, useLocation} from 'react-router-dom'
import * as Yup from 'yup'
import {Form, Input, PasswordInput, qs, Submit} from '@evet/library'
import {StepNavigator} from 'pages/Auth/SignUp/Guard/StepNavigator'
import {Validator} from 'rest/helpers/Validations'
import {PolicyAndTerms} from 'components/extras'
import {StepWrapper} from '../StepWrapper'

export const Step1 = inject(
  'AuthStore',
  'OfferStore'
)(
  observer(props => {
    const {
      name,
      family_name,
      email,
      password,
      phone: phone_number,
      client_url,
    } = new Validator()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {search} = useLocation()
    const params = qs.parse(search)
    const meta = props.OfferStore.meta
    const data = props.AuthStore.signupCache

    const schema = Yup.object().shape({
      name,
      family_name,
      email,
      password,
      phone_number,
      client_name: Yup.string().required(t('Required')),
      client_url,
      quote: Yup.string(),
    })

    const defaultValues = {
      ...data,
      plan: params.plan || data.plan || meta.defaultPlan,
    }

    const handleSubmit = async _data => {
      const {sessionID: guid} = props.AuthStore.signupCache
      if (await props.AuthStore.checkEmail({..._data, guid})) {
        props.AuthStore.signupCache = {...data, ..._data}
        await navigate('/register/guard/plan?' + qs.stringify(params))
      }
    }

    const {resetSignupCache, authenticated} = props.AuthStore

    useEffect(() => {
      if (!data.sessionID || data._expire < new Date().getTime()) {
        resetSignupCache()
      }
    }, [resetSignupCache, data.sessionID, data._expire])

    useEffect(() => {
      ;(async () => {
        if (authenticated) {
          await navigate('/')
        }
      })()
    }, [navigate, authenticated])

    return (
      <StepWrapper
        page="CREATE ACCOUNT"
        title={t('Evet Search Compliance & Monitoring')}
        description={t('Start our free 3 day trial')}
      >
        <StepNavigator step={1} />
        <div className="flex-none flex flex-col w-11/12 md:w-8/12 lg:w-5/12  justify-center items-center ">
          <Form
            onSubmit={handleSubmit}
            schema={schema}
            defaultValues={defaultValues}
          >
            <div>
              <Input label={t('First Name')} name="name" />
            </div>
            <div className="mt-5">
              <Input label={t('Last Name')} name="family_name" />
            </div>
            <div className="mt-5">
              <Input label={t('Email Address')} name="email" />
            </div>
            <div className="mt-5">
              <PasswordInput label={t('Password')} name="password" />
            </div>
            <div className="mt-5">
              <Input label={t('Business Name')} name="client_name" />
            </div>
            <div className="mt-5">
              <Input label={t('Business Website URL')} name="client_url" />
            </div>
            <div className="mt-5">
              <Input label={t('Phone Number')} name="phone_number" />
            </div>
            {defaultValues.plan === 'custom' && (
              <div className="mt-5">
                {/*TODO we need a textarea component [GC]*/}
                <Input
                  label={t(
                    'What type of search engine monitoring solution you need?'
                  )}
                  name="quote"
                  multiline
                  rows={3}
                />
              </div>
            )}
            <div className="mt-2.5 w-full align-middle flex flex-row">
              <PolicyAndTerms byClicking={t('Continue')} />
            </div>
            <Submit className="text-sm mt-5 py-2 w-full" primary>
              {t('Continue')}
            </Submit>
          </Form>
        </div>
        <div className="justify-center align-middle flex-none flex flex-row w-full mt-7 mb-14">
          <p className="font-light text-sm">{t('Already have an account?')}</p>
          <Link className="text-sm text-primary ml-4" to="/login">
            {t('Sign in')}
          </Link>
        </div>
      </StepWrapper>
    )
  })
)
