import {inject, observer} from 'mobx-react'
import React from 'react'
import {useTranslation} from 'react-i18next'

export const Currency = inject('UIStore')(
  observer(props => {
    const {currency, currencyRatio} = props.UIStore
    const {value, children} = props
    //const {t} = useTranslation(['common']);
    const {t} = useTranslation()
    const amount = (value || children) * currencyRatio

    return (
      <span>
        {t('currency', {
          currency,
          value: !isNaN(amount) ? amount.toFixed(0) : 0,
        })}
      </span>
    )
  })
)
