import {inject, observer} from 'mobx-react'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {
  CheckBox,
  Form,
  GoBack,
  Input,
  Label,
  qs,
  SelectFilter,
  Submit,
} from '@evet/library'
import {CommonServices as Services} from 'rest/services'
import {PolicyAndTerms} from 'components/extras'
import {StepNavigator} from 'pages/Auth/SignUp/Network/StepNavigator'
import {StepWrapper} from '../../StepWrapper'

export const Step2 = inject(
  'AuthStore',
  'OfferStore',
  'SystemMessageStore'
)(
  observer(props => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {search} = useLocation()
    const params = qs.parse(search)
    const data = props.AuthStore.signupCache

    const [categories, setCategories] = useState([])
    const [types, setTypes] = useState([])
    const [networkCountries, setNetworkCountries] = useState([])

    const schema = Yup.object().shape({
      publisher: Yup.object({
        service_areas: Yup.array().required('Required'),
        categories: Yup.array().required('Required'),
        uri: Yup.string().required('Required'),
        types: Yup.array().required('Required'),
      }),
    })

    const {meta: coefficients, list} = props.OfferStore
    const offers = []
    list.forEach(i => {
      offers.push({
        ...i,
        label: i.offer_name,
        value: i.offer_id,
        price: i.prices[0].price,
        currency_type_cd: i.prices[0].currency_type_cd,
        keyword_limit: i.settings.keywords_count,
        frequency: i.settings.frequency,
      })
    })

    const defaultValues = {
      ...data,
      keyword_limit: data.keyword_limit || 150,
      frequency: data.frequency || 12,
      trademarks: data.trademarks || [],
      country_id: data.country_id || 1,
      plan: data.plan || params.plan || coefficients.default_plan || 'custom', //TODO coeff default plan couldt set as default
      is_annual: data.is_annual || 'false',
      // trademarks: ['trademark'],
    }

    useEffect(() => {
      Services.NetworkCategories()
        .then(res =>
          setCategories(res.items.map(i => ({value: i.id, label: i.name})))
        )
        .catch(err => {
          setCategories([])
          props.SystemMessageStore.handleError(err)
        })
      // eslint-disable-next-line
    }, [])

    useEffect(() => {
      Services.NetworkCountries()
        .then(res =>
          setNetworkCountries(
            res.items.map(i => ({value: i.country_code, label: i.name}))
          )
        )
        .catch(err => {
          setNetworkCountries([])
          props.SystemMessageStore.handleError(err)
        })
      // eslint-disable-next-line
    }, [])

    useEffect(() => {
      Services.NetworkPublisherTypes()
        .then(res =>
          setTypes(res.items.map(i => ({value: i.id, label: i.name})))
        )
        .catch(err => {
          setTypes([])
          props.SystemMessageStore.handleError(err)
        })
      // eslint-disable-next-line
    }, [])

    const handleSubmit = async _data => {
      // BEGIN data > publisher > types in icinde boolean gelen degerleri kaldiriyor
      const payload = {..._data}
      payload.publisher.types = payload.publisher.types.filter(
        i => typeof i !== 'boolean'
      )
      // END data > publisher > types in icinde boolean gelen degerleri kaldiriyor

      const res = await props.AuthStore.createPublisher({...data, ...payload})
      if (res) {
        await navigate(
          '/register/network/publisher/done?' + qs.stringify(params)
        )
      }
    }
    const handleBack = async _data => {
      _data['trademarks'] = (_data['trademarks'] || []).filter(
        i => i && i !== ''
      )
      props.AuthStore.signupCache = {...data, ..._data, offers}
      await navigate('/register/network/publisher?' + qs.stringify(params))
    }

    return (
      <StepWrapper
        page="PUBLISHER"
        title={t('Become a Evet Publisher')}
        className=" items-center w-full max-w-full flex flex-col transition-all bg-gray-50 "
        description={t(
          'Sign up now and start promoting the world’s top brands via Evet’s Global Affiliate Marketing Platform. ' +
            'Already a Evet Publisher? Log in.'
        )}
      >
        <div className="flex flex-col items-center w-11/12 md:w-10/12 lg:w-8/12">
          <StepNavigator step={2} />
          <div className="flex-none flex flex-col sm:flex-row justify-around items-start w-full mb-8">
            <div className="w-full sm:w-7/12 ">
              <Form
                onSubmit={handleSubmit}
                schema={schema}
                defaultValues={defaultValues}
              >
                <div className="mt-5">
                  <Input
                    label={t('Website URL')}
                    className="text-sm"
                    placeholder="http://www.example.com"
                    name="publisher.uri"
                  />
                </div>
                <Label className="mt-5 mb-3 font-semibold">
                  {t('Publisher Type')}
                </Label>
                <div className=" flex justify-between w-full">
                  {types.map((i, index) => (
                    <CheckBox
                      className={i.label}
                      name={`publisher.types[${index}]`}
                      key={`publisherType-${i.value}`}
                      label={i.label}
                      value={i.value}
                    />
                  ))}
                </div>
                <div className="grid sm:grid-cols-2 gap-4 mt-5">
                  <div>
                    <SelectFilter
                      className="bg-white"
                      label="Service Areas"
                      name="publisher.service_areas"
                      options={networkCountries}
                      size="4"
                      multiple
                    />
                  </div>
                  <div>
                    <SelectFilter
                      className="bg-white"
                      label="Category"
                      name="publisher.categories"
                      options={categories}
                      size="4"
                      multiple
                    />
                  </div>
                </div>
                <div className="mt-4 flex">
                  <PolicyAndTerms
                    className="text-center"
                    byClicking={t('CREATE')}
                  />
                </div>
                <div className="grid grid-cols-2 gap-4 mt-8">
                  <GoBack
                    handleBack={handleBack}
                    className="text-sm py-2"
                    secondary
                  >
                    {t('BACK')}
                  </GoBack>
                  <Submit className="text-sm py-2" primary>
                    {t('CREATE')}
                  </Submit>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </StepWrapper>
    )
  })
)
