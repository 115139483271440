import {request} from 'rest/helpers'

export class Services {
  static createUser = async data => {
    // TODO BB migrate to v2
    return await request.post('/account/create/', data, false)
  }

  static create = async data => {
    return await request.post('/users/', data)
  }

  static get = async params => {
    return await request.get('/users/', params)
  }

  static detail = async id => {
    if (id) return await request.get('/users/' + id)
  }

  static update = async (id, data) => {
    return await request.put('/users/' + id, data).then()
  }

  static updateProfile = async data => {
    return await request.put('/account/profile/', data).then()
  }

  static delete = async (url_id, id) => {
    return await request.delete('/users/' + id, {url_id}).then()
  }

  static signIn = async data => {
    return await request.post('/token/create/', data, false).then()
  }

  static signOut = (from = null) => {
    request.deleteCookies()
    const to = '/login' + (from ? '?from=' + from : '')
    setTimeout(() => (window.location.href = to), 100)
  }

  static reset = async data =>
    await request.post('/forgot-password/', data, false)

  static newPassword = async data =>
    await request.post('/confirm-forgot-password/', data, false)

  static changePassword = async data =>
    await request.post('/auth/change-password/', data)

  static activation = async data =>
    await request.post('/activate/', data, false)

  static verify = async data => await request.post('/verify/', data, false)

  static newsletter = async data => {
    return await request.post('/newsletter/', data, false)
  }

  static getProfile = async () => {
    return await request.get('/account/profile/', null, true)
  }

  // static validate = async data => {
  //   return await request.post('/account/validate', data, false)
  // }

  static createPublisher = async data =>
    await request.post('/register/ghost/publisher/', data, false)

  static createGuard = async data =>
    await request.post('/register/secretone/', data, false)

  static checkEmail = async ({email}) =>
    await request.post('/users/check-email/', {email}, false)
}
