import {
  Form,
  GoBack,
  IncrementalInput,
  qs,
  Select,
  Slider,
  Submit,
  useFormContext,
} from '@evet/library'
import {inject, observer} from 'mobx-react'
import {StepNavigator} from 'pages/Auth/SignUp/Network/StepNavigator'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {getFrequencyProps} from '../../CalcFunctions'
import {InfoCard} from '../../InfoCard'
import {PlanCard} from '../../PlanCard'
import {StepWrapper} from '../../StepWrapper'

const CustomFields = props => {
  const {watch} = useFormContext()
  const {t} = useTranslation()
  const coefficients = props.coefficients
  const frequency = watch('frequency')
  const frequencyProps = getFrequencyProps(frequency, coefficients)
  // const keywords = watch('keyword_limit')
  // const keywordProps = frequencyProps ? getKeywordProps(keywords, frequencyProps) : {}
  const {min_frequency: frequencyMin = 0, max_frequency: frequencyMax = 0} =
    coefficients || {}
  const {keyword_min: keywordMin = 0, keyword_max: keywordMax = 0} =
    frequencyProps || {}
  return (
    watch(props.watch) === 'custom' && (
      <div className="flex flex-row justify-between w-full mt-5">
        <Slider
          label={t('Daily Frequency')}
          info={t(
            'This is how frequently you want your Branded keyword terms to be monitored on a daily basis by our automated monitoring system.'
          )}
          min={frequencyMin}
          max={frequencyMax}
          name="frequency"
          className="w-5/12"
          marks
        />
        <Slider
          label={t('Keyword')}
          info={t(
            "This is how many keyword variations you want our automated solution technology to monitor your company's Branded keyword terms. "
          )}
          min={keywordMin}
          max={keywordMax}
          step={1}
          name="keyword_limit"
          className="w-5/12"
          marks
        />
      </div>
    )
  )
}

export const Step2 = inject(
  'AuthStore',
  'CountryStore',
  'OfferStore'
)(
  observer(props => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {search} = useLocation()
    const params = qs.parse(search)
    const data = props.AuthStore.signupCache

    const [loading, setLoading] = useState(false)

    const schema = Yup.object().shape({
      trademarks: Yup.array(Yup.string())
        .ensure()
        .compact()
        .required(t('Required')),
      plan: Yup.string().required(t('Required')),
      country_id: Yup.number().required(t('Required')),
    })

    const {meta: coefficients, list} = props.OfferStore
    const offers = []
    list.forEach(i => {
      offers.push({
        ...i,
        label: i.offer_name,
        value: i.offer_id,
        price: i.prices[0].price,
        currency_type_cd: i.prices[0].currency_type_cd,
        keyword_limit: i.settings.keywords_count,
        frequency: i.settings.frequency,
      })
    })

    const defaultValues = {
      ...data,
      keyword_limit: data.keyword_limit || 150,
      frequency: data.frequency || 12,
      trademarks: data.trademarks || [],
      country_id: data.country_id || 1,
      plan: data.plan || params.plan || coefficients.default_plan || 'custom', //TODO coeff default plan couldt set as default
      is_annual: data.is_annual || 'false',
      // trademarks: ['trademark'],
    }

    // const coefficients = props.CoefficientStore.item
    // console.log({coefficients})
    const searchCountries = props.CountryStore.list.map(i => ({
      label: i.country_name,
      value: i.country_id,
    }))

    const handleSubmit = async _data => {
      _data['trademarks'] = _data['trademarks'].filter(i => i && i !== '')
      const isAPlan = coefficients.plans
        ? coefficients.plans[_data.frequency + '-' + _data.keyword_limit]
        : false

      props.AuthStore.signupCache =
        isAPlan || _data.plan !== 'custom'
          ? {
              ...data,
              ..._data,
              account_type: 'STANDARD',
              offer_id: isAPlan || _data.plan,
              offers,
            }
          : {...data, ..._data, account_type: 'OPM', offers}

      const {sessionID: guid} = props.AuthStore.signupCache
      setLoading(true)
      if (
        await props.AuthStore.validate({
          ...props.AuthStore.signupCache,
          trademarks: _data.trademarks.join(','),
          guid,
        })
      ) {
        await navigate(
          '/register/payment' + qs.stringify(params, {addQueryPrefix: true})
        )
      } else {
        setLoading(false)
      }
    }
    const handleBack = async _data => {
      _data['trademarks'] = _data['trademarks'].filter(i => i && i !== '')
      props.AuthStore.signupCache = {...data, ..._data, offers}
      await navigate('/register' + qs.stringify(params, {addQueryPrefix: true}))
    }

    useEffect(() => {
      if (!data.email) {
        navigate('/register' + qs.stringify(params, {addQueryPrefix: true}))
      }
    }, [navigate, params, data.email])

    return (
      <StepWrapper
        page={t('ADVERTISER')}
        title={t('Become a Evet + Advertiser')}
        description={t(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Already a Evet + Publisher?  Login.'
        )}
      >
        <StepNavigator step={2} />

        <div className="flex flex-row justify-between w-full md:w-9/10 lg:w-3/5  my-8  ">
          <Form
            onSubmit={handleSubmit}
            schema={schema}
            defaultValues={defaultValues}
            className="w-full md:flex-row md:justify-between flex flex-col justify-center"
          >
            <div className="w-9/10 md:w-full">
              <Select
                label={t('Select Plan')}
                className="bg-white w-full"
                name="plan"
                options={[...offers, {label: 'Custom', value: 'custom'}]}
              />
              <CustomFields coefficients={coefficients} watch="plan" />
              <IncrementalInput
                max={coefficients.max_trademark_count}
                name="trademarks"
                label={t('Add your Branded Term(s) ')}
                className={'w-full mt-5'}
                placeholder={[t('e.g., macys'), t('e.g., macys.com')]}
                innerClass=" bg-gray-50 focus:bg-gray-100"
              />
              <Select
                label={t('Choose Country')}
                info={t(
                  'This feature allows you to choose which Country you want our team to be monitoring when looking for "Unauthorized" Bidders.'
                )}
                className="bg-white w-full mt-5"
                name="country_id"
                options={searchCountries}
              />
              <Submit loading={loading}>{t('Continue')}</Submit>
              <div className="flex flex-row items-center justify-center w-full mt-8 mb-16">
                <GoBack
                  onBack={handleBack}
                  className="text-link text-sm font-roboto font-normal pr-3"
                >
                  <i className="icon-left-dir ml-1 p-0" />
                  {t('Go back')}
                </GoBack>
              </div>
            </div>
            <div className="w-1/2 md:w-full">
              <InfoCard />
              <div className="info-card plan-overview mt-2.5">
                <PlanCard coefficients={coefficients} offers={offers} />
              </div>
            </div>
          </Form>
        </div>
      </StepWrapper>
    )
  })
)
