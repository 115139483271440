import * as Yup from 'yup'
import i18next from './i18'

export class Validator {
  get name() {
    return Yup.string().required(i18next.t('Required'))
  }

  get first_name() {
    return Yup.string().required(i18next.t('Required'))
  }

  get family_name() {
    return Yup.string().required(i18next.t('Required'))
  }

  get last_name() {
    return Yup.string().required(i18next.t('Required'))
  }

  get email() {
    return Yup.string()
      .required(i18next.t('Required'))
      .email(i18next.t('Must be a valid email'))
  }

  get password() {
    return Yup.string()
      .required(i18next.t('Required'))
      .matches(
        /[A-Z]+/,
        i18next.t('Must include at least one uppercase character')
      )
      .matches(
        /[a-z]+/,
        i18next.t('Must include at least one lowercase character')
      )
      .matches(/[0-9]+/, i18next.t('Must contain a number'))
      .matches(
        /[!@#$%\\^&*)(+=._-]+/,
        i18next.t('Must include at least one special character')
      )
      .min(8, i18next.t('Too short - should be 8 chars minimum'))
  }

  get confirm_password() {
    return Yup.string().oneOf(
      [Yup.ref('password'), null],
      i18next.t('Passwords must match')
    )
  }

  get weak_password() {
    return (
      Yup.string()
        .required(i18next.t('Required'))
        // .matches(
        //   /[A-Z]+/,
        //   i18next.t('Must include at least one uppercase character')
        // )
        .matches(
          /[a-z]+/,
          i18next.t('Must include at least one lowercase character')
        )
        .matches(/[0-9]+/, i18next.t('Must contain a number'))
        // .matches(
        //   /[!@#$%\\^&*)(+=._-]+/,
        //   i18next.t('Must include at least one special character')
        // )
        .min(8, i18next.t('Too short - should be 8 chars minimum'))
    )
  }

  get weak_confirm_password() {
    return Yup.string().oneOf(
      [Yup.ref('weak_password'), null],
      i18next.t('Passwords must match')
    )
  }

  get remember() {
    return Yup.boolean()
  }

  get phone() {
    return Yup.string()
      .required(i18next.t('Required'))
      .matches(
        /^(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?$/,
        'Must be valid phone number'
      )
  }

  get client_url() {
    return Yup.string().required(i18next.t('Required'))
  }

  get company_url() {
    return Yup.string().required(i18next.t('Required'))
  }

  get requiredString() {
    return Yup.string().required(i18next.t('Required'))
  }
}
