export class Meta {
  constructor(data) {
    // if (data)
    // 	Object.entries(data).forEach(([k, i]) => {
    //
    // 		const key = k.replace(/_([a-z])/ig, ($1) => $1.toUpperCase().replace(/_/g, ''));
    // 		this[key] = i;
    // 		const entries = Object.entries(i);
    // 		if(entries.length){
    // 			this[key] = {};
    // 			entries.forEach(([sk, si]) => {
    // 				this[key][sk.replace(/_([a-z])/ig, ($1) => $1.toUpperCase().replace(/_/g, ''))] = si;
    // 			})
    // 		}
    // 	})

    this.default_plan = data.default_plan //Object.values(data.plans)[0];
    // const x = {
    // 	plans: {'6-150': '1,1', '12-500': '2,3'},
    // 	frequencyMin: 6,
    // 	frequencyMax: 24,
    // 	trademarkMin: 1,
    // 	trademarkMax: 3,
    // 	frequencyLabels: ['6-9', '10-12', '13-16', '17-24'],
    // 	coefficients: {
    // 		'6-9': {
    // 			keywordMin: 50,
    // 			keywordMax: 250,
    // 			frequencyCoefficient: 5,
    // 			keywordLabels: ['50-59', '60-74', '75-99', '100-250'],
    // 			keywordCoefficients: {'50-59': 0.38, '60-74': 0.40, '75-99': 0.42, '100-250': 0.45},
    // 		},
    // 		'10-12': {
    // 			keywordMin: 100,
    // 			keywordMax: 500,
    // 			frequencyCoefficient: 5.3,
    // 			keywordLabels: ['50-59', '60-74', '75-99', '100-500'],
    // 			keywordCoefficients: {'50-59': 0.40, '60-74': 0.42, '75-99': 0.43, '100-500': 0.45},
    // 		},
    // 		'13-16': {
    // 			keywordMin: 100,
    // 			keywordMax: 500,
    // 			frequencyCoefficient: 6,
    // 			keywordLabels: ['50-59', '60-74', '75-99', '100-500'],
    // 			keywordCoefficients: {'50-59': 0.45, '60-74': 0.47, '75-99': 0.50, '100-500': 0.52},
    // 		},
    // 		'17-24': {
    // 			keywordMin: 100,
    // 			keywordMax: 1000,
    // 			frequencyCoefficient: 6.3,
    // 			keywordLabels: ['50-59', '60-74', '75-99', '100-1000'],
    // 			keywordCoefficients: {'50-59': 0.50, '60-74': 0.53, '75-99': 0.55, '100-1000': 0.60},
    // 		},
    // 	}
    // };
    Object.entries(data).map(([k, v]) => (this[k] = v))
    // snakeToCamel = str => str.replace(/([-_]\w)/g, g => g[1].toUpperCase())
  }
}

export class Offer {
  constructor(data) {
    if (data) {
      Object.entries(data).forEach(([k, i]) => (this[k] = i))
    }
    //snakeToCamel = str => str.replace(/([-_]\w)/g, g => g[1].toUpperCase())
  }

  // get urlID(){
  //   return this.url_id
  // }
}
