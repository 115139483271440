import {request as Request} from '@evet/library'

const isDev = process.env.NODE_ENV !== 'production'
export const origin = window.location.origin.replace(
  ':' + window.location.port,
  ''
)
const cookie_domain = isDev
  ? origin.replace('http://', '')
  : process.env.REACT_APP_COOKIE_DOMAIN
const auth_url = isDev ? window.location.origin : process.env.REACT_APP_AUTH

export const request = new Request({
  api_url: process.env.REACT_APP_API_URL,
  cookie_domain,
  refresh_token_url: process.env.REACT_APP_REFRESH_TOKEN,
  auth_url,
  isAuth: true,
})
