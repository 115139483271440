import {UIStore} from './UIStore'
import SystemMessageStore from './SystemMessageStore'

import TimezoneStore from './TimezoneStore'
import CountryStore from './CountryStore'
import OfferStore from './OfferStore'

import AuthStore from './AuthStore'

class RootStore {
  constructor() {
    this.UIStore = new UIStore(this)
    this.SystemMessageStore = new SystemMessageStore(this)

    this.TimezoneStore = new TimezoneStore(this)
    this.CountryStore = new CountryStore(this)
    this.OfferStore = new OfferStore(this)

    this.AuthStore = new AuthStore(this)
  }
}

export const stores = new RootStore()
