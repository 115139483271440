import React from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import {LayoutPrivate} from 'layouts'

export const PrivateRoute = ({
  layout: Layout = LayoutPrivate,
  authenticated,
  ...rest
}) => {
  return authenticated ? (
    // <Route
    //   basename={basename}
    //   path={path}
    //   element={
    //     <Layout>
    //       <Component />
    //     </Layout>
    //   }
    // >
    //   {children}
    // </Route>
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate
      to={{
        pathname: '/login',
        state: {from: rest.location},
      }}
    />
  )
}
