import {inject, observer} from 'mobx-react'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {Loader} from '@evet/library'

export const Verify = inject('AuthStore')(
  observer(props => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {search} = useLocation()
    const params = useParams()
    const verification_key = params.token || search.replace('?key=', '')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
      setLoading(true)
      props.AuthStore.verify({verification_key}).then(res => {
        ;(async () => {
          if (res) {
            setTimeout(() => navigate('/login'), 2500)
          } else {
            setError(t('Please, try again later.'))
            setLoading(false)
          }
        })()
      })
    }, [navigate, t, verification_key, props.AuthStore])

    return (
      <section className="w-full h-full flex items-center justify-center ">
        <div className="p-5 text-sm">
          {loading && <Loader />}
          {error}
        </div>
      </section>
    )
  })
)
