import React from 'react'
import {useTranslation} from 'react-i18next'
import {ReactComponent as IconSelectPlan} from 'assets/icons/SelectPlan.svg'

export const StepNavigator = ({step}) => {
  const {t} = useTranslation()
  return (
    <div
      className="flex justify-between items-center -mt-7 mb-5
    text-center sm:text-left mx-2 sm:mx-0"
    >
      <div
        className={
          'flex flex-col sm:flex-row sm:justify-start justify-center items-center ' +
          'border py-2  ' +
          'px-6 rounded-l-md ' +
          '' +
          (step === 1 ? ' bg-white ' : 'bg-gray-100 ')
        }
      >
        <i className="icon-user text-2xl sm:mr-3" />
        <div className="flex flex-col">
          <span className="text-xs hidden sm:block text-gray">
            {t('Step 1')}
          </span>
          <p className="text-sm ">{t('Create Account')}</p>
        </div>
      </div>
      <div
        className={
          'flex flex-col sm:flex-row justify-start items-center border py-2 px-6 ' +
          (step === 2 ? ' bg-white ' : 'bg-gray-100 ')
        }
      >
        <i className="m-1.5 ml-1 sm:mr-4">
          <IconSelectPlan />
        </i>
        <div className="flex flex-col">
          <span className="text-xs hidden sm:block text-gray">
            {t('Step 2')}
          </span>
          <p className="text-sm">{t('Select Plan')}</p>
        </div>
      </div>
      <div
        className={
          'flex flex-col sm:flex-row justify-start items-center border py-2 px-6 rounded-r-md ' +
          (step === 3 ? ' bg-white ' : 'bg-gray-100 ')
        }
      >
        <i className="icon-credit-card text-2xl sm:mr-3" />
        <div className="flex flex-col">
          <span className="text-xs hidden sm:block text-gray">
            {t('Step 3')}
          </span>
          <p className="text-sm">{t('Payment Method')}</p>
        </div>
      </div>
    </div>
  )
}
