import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

export const TermsOfServices = () => {
  const {t} = useTranslation()

  return (
    <section className="w-full bg-gray-50">
      <h1 className="font-bold text-xl mb-2 text-gray-700 container mx-auto sm:px-0 px-3 relative">
        <small className="block text-xs text-gray-400 font-light mb-1">
          <Link to="/">Home</Link> / Terms of Service
        </small>
        Terms of Service
      </h1>
      <div className="relative bg-white">
        <div className="container mx-auto pt-5 pb-24 sm:px-0 px-3">
          <p className="text-gray-700 font-light">
            <strong className="font-semibold">{t('Acceptance')}</strong>
            <br />
            <br />
            Please read these Terms of Service (&quot;Agreement&quot;,
            &quot;Contract&quot;) carefully before using evet.com (&quot;the
            Site&quot;) operated by Evet Technologies, Inc. (&quot;us&quot;,
            &quot;we&quot;, or &quot;Evet&quot;).
            <br />
            <br />
            Your use of Evet’s products, software, services and web sites
            (referred to collectively as the &quot;Services&quot;) is subject to
            the terms of a contract between you and Evet (these
            &quot;Terms&quot;). These Terms form a legally binding agreement
            between you and Evet in relation to your use of the Services. Evet
            Technologies, Inc. reserves the right to modify, suspend, or
            discontinue the Service at any time for any reason with or without
            notice. To use the Services, you must first agree to the Terms. By
            accepting these Terms, you represent that you have the legal power
            to do so. If you will be using the Services on behalf of an
            organization, (a) &quot;you&quot; and &quot;your&quot; will refer to
            that organization, (b) you agree to these Terms on behalf of that
            organization and you represent that you have the authority to do so,
            and (c) you shall be responsible for ensuring that each of your end
            users complies with the terms, conditions and obligations in these
            Terms.
            <br />
            <br />
            <strong className="font-semibold">Registration</strong>
            <br />
            <br />
            Companies may obtain a 3-day free trial membership with the Service
            without paying a fee. Trial accounts are not required to provide a
            credit card number. You can continue with one of the subscription
            plans after the free-trial expires. When completing the sign-up
            form, you will provide true, accurate, and complete information, and
            maintain and promptly update this information. You will enter a
            password and username during the registration process. You are
            responsible for maintaining the confidentiality of your password and
            username, and are responsible for all activities that occur with
            your password and login name, including fees incurred and all
            purchases made by your membership on the Service. This means that,
            unless your password and login name, or credit card information is
            obtained unlawfully or fraudulently, you will be responsible for all
            fees incurred and purchases made by your membership. You will
            immediately notify Evet Support of any unauthorized use of your
            membership or any other breach of security.
            <br />
            <br />
            If you enter your billing information during or after the
            free-trial, your account will automatically be charged with the base
            package plan unless you upgrade it to other subscription plans. You
            may use a credit card to pay monthly or annual subscription fees.
            You must agree to provide a valid credit card. When you use a credit
            card for payment, you represent to Evet that you are the authorized
            card holder. For recurring fees, Evet will charge your credit card
            each month. Evet reserves the right to change its fees or billing
            methods. If any change is unacceptable to you, you may cancel your
            subscription, but Evet will not refund any fees that may have
            accrued to your membership before you cancel it. There will be no
            refunds or credits for partial months of service, upgrade/downgrade
            refunds, or refunds for months unused with an open account. Evet may
            also charge you for any sales or use taxes to which the Service is
            subject. Evet reserves the right to change our monthly/annually fees
            upon 30 days notice from us. Fee change will be notified per email
            to all our subscribers and will be reflected on the pricing page at
            evet.com/pricing.
            <br />
            <br />
            By entering into a monthly or yearly subscription with us, you are
            explicitly authorizing us to charge your credit card on a recurring
            basis.
            <br />
            <br />
            You represent and warrant to Evet: (a) that you have full power,
            authority, and legal capacity to enter into the Agreements and
            follow its obligations, and if you are registering on behalf of a
            company or other entity, you have the authority to bind your
            principal or employer company; (b) you will provide complete and
            accurate information to Evet; and (c) you will pay all charges that
            you incur at Evet’s then-current rates.
            <br />
            <br />
            You have the right to terminate or cancel your subscription at any
            time. You understand and agree that the cancellation of your
            membership or a particular component of the Service is your sole
            right. You alone are responsible for properly cancelling your
            Account. An email or phone request to cancel your Account shall
            result in cancellation. You can also cancel your service by clicking
            “cancel subscription” button in Evet’s client dashboard. Any
            cancellation of your Account will result in the deactivation or
            deletion of your Account or your access to your Account, and the
            forfeiture and relinquishment of all Content in your Account. This
            information cannot be recovered from Evet once your account is
            cancelled.
            <br />
            <br />
            You must agree to ensure accuracy, legibility, and completeness of
            all data supplied to Evet Technologies, Inc. and be solely
            responsible for the results obtained from Customer’s use of any of
            the Services;
            <br />
            <br />
            You agree to provide accurate, complete and up to date information
            only. You must agree to control, and be responsible for the use of,
            Account information, user ids, and passwords related to the Services
            and, where required.
            <br />
            <br />
            You are responsible for your conduct, Content, and communications
            with others while using the Services. In addition to restrictions
            found elsewhere in these Terms, you agree not to:
            <br />
            <br />
            <strong className="font-semibold">(a)</strong> copy, modify, create
            a derivative work of, reverse engineer, decompile or otherwise
            attempt to extract the source code of the Software or any part
            thereof, unless expressly permitted;
            <br />
            <br />
            <strong className="font-semibold">(b)</strong> access all or any
            part of the Services in order to build a product or service which
            competes with the Software or Services;
            <br />
            <br />
            Evet Technologies, Inc. makes no warranties regarding (i) your
            ability to use the Service, (ii) your satisfaction with the Service,
            (iii) that the Service will be available at all times,
            uninterrupted, and error-free (iv), the accuracy of mathematical
            calculations performed by the Service, and (v) that bugs or errors
            in the Service will be corrected. In order to register for and
            access certain Services, you will be required to provide information
            about yourself. You agree that any registration information you give
            to Evet, including contact information (e.g., e-mail address),
            postal address and billing details, will be accurate and kept
            current. Accounts are controlled by the entity whose email address
            is registered with the account.
            <br />
            <br />
            You acknowledge and agree that if you are a registered Evet user,
            and the domain of the primary email address associated with your
            Evet Search Compliance and Monitoring account is owned by an
            organization, and an authorized representative of that organization
            wishes to establish a multiple user accounts and add you to it, then
            certain information concerning your individual account may become
            accessible to that organization’s administrator, including your name
            and email address.
            <br />
            <br />
            Evet Technologies, Inc. may disable or terminate your access for any
            conduct in violation of these Terms. You acknowledge and agree that
            if Evet disables access to your account, you may be prevented from
            accessing the Services, your account details, or any files or other
            Content contained in your account.
          </p>
          <br />
          <br />
          <span className="font-bold">{t('Evet Team')}</span>
          <br />
          <span className="font-bold text-primary">
            {t('Evet Technologies, Inc.')}
          </span>
        </div>
      </div>
    </section>
  )
}

