import i18next from 'i18next'
import translationEN from 'locales/en/translation.json'
// import translationTR from "locales/tr/translation.json";

i18next.init({
  interpolation: {escapeValue: false}, // React already does escaping
  lng: 'en-US', // language to use
  // parseMissingKeyHandler: (key) => {console.log('MISSING KEY',{key})},
  keySeparator: false, // we do not use keys in form messages.welcome
  react: {
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span'], // don't convert to <1></1> if simple react elements
  },
  resources: {
    'en-US': {
      translation: translationEN,
    },
    // tr: {
    //   translation: translationTR
    // }
  },
})

export default i18next
