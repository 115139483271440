export const getFrequencyProps = (val, props) => {
  const label = (props.frequency_labels || [])
    .filter(i => {
      const [min, max] = i.split('-')
      return val >= min && val <= max
    })
    .pop()
  return label ? props.coefficients[label] : {}
}
export const getKeywordProps = (val, props) => {
  const label = (props.keyword_labels || [])
    .filter(i => {
      const [min, max] = i.split('-')
      return val >= min && val <= max
    })
    .pop()
  // console.log({label}, props, props.keywordCoefficients);
  return label ? props.keyword_coefficients[label] : {}
}
export const priceCalc = (
  frequency,
  frequencyCoefficient,
  keywords,
  keywordCoefficient
) => {
  return frequency * frequencyCoefficient + keywords * keywordCoefficient
}
