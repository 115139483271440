import React from 'react'
import {useTranslation} from 'react-i18next'

export const PolicyAndTerms = ({
  byClicking = 'Create',
  className = '',
  mini = false,
}) => {
  const {t} = useTranslation()

  const TermsOfServiceButton =
    '<a class="text-primary font-medium " href="/terms-of-services" ' +
    ' target="_blank" rel="noopener noreferrer"> ' +
    (mini ? t('Terms') : t('Terms of Service')) +
    ' </a>'

  const PrivacyPolicyButton =
    '<a class="text-primary font-medium " ' +
    'href="/privacy-policy" target="_blank" rel="noopener noreferrer"> ' +
    (mini ? t('Privacy') : t('Privacy Policy')) +
    '</a>'

  return mini ? (
    <span
      className="text-xs font-light mt-5"
      dangerouslySetInnerHTML={{
        __html: t('{{TermsOfServiceButton}} & {{PrivacyPolicyButton}}.', {
          TermsOfServiceButton,
          PrivacyPolicyButton,
        }),
      }}
    />
  ) : (
    <span
      className={`text-xs text-gray-600 font-light mt-5 text-left ${className}`}
      dangerouslySetInnerHTML={{
        __html:
          t(
            'By clicking `{{byClicking}}`, you agree to the {{TermsOfServiceButton}} and {{PrivacyPolicyButton}}.',
            {
              byClicking,
              TermsOfServiceButton,
              PrivacyPolicyButton,
            }
          ) +
          ' ' +
          t(
            'You also agree to receive information and offers relevant to our services via email.'
          ),
      }}
    />
  )
}
