import React from 'react'
import {useTranslation} from 'react-i18next'
import {ButtonGoHome} from 'components'

export const NoMatch = () => {
  const {t} = useTranslation()
  return (
    <section className="grow h-full flex justify-center items-center">
      <div className="p-4 mb-24 flex flex-col justify-center items-center w-max">
        <p className="text-7xl font-extrabold text-primary sm:text-9xl">404</p>

        <h1 className="text-3xl font-bold text-gray-900 tracking-normal sm:text-3xl">
          {t('Whoops!')}
        </h1>

        <p className="text-center mt-1 text-sm text-gray-500">
          {t("We couldn't find the page you were looking for.")}
        </p>

        <ButtonGoHome className="mt-6 -ml-4" />
      </div>
    </section>
  )
}
