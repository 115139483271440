import {inject, observer} from 'mobx-react'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {Form, Input, PasswordInput, Submit} from '@evet/library'
import {Validator} from 'rest/helpers/Validations'
import {PolicyAndTerms} from 'components/extras'
import {Done} from './Done'

export const Activate = inject('AuthStore')(
  observer(props => {
    const {first_name, last_name, password, confirm_password} = new Validator()
    const params = useParams()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const schema = Yup.object().shape({
      activation_key: Yup.string().required(t('Activation key is required')),
      first_name,
      last_name,
      password,
      confirm_password,
    })

    const defaultValues = {activation_key: params.token}

    const isDone = params.token === 'done'

    const onSubmit = async data => {
      setLoading(true)
      if (await props.AuthStore.activation(data)) {
        await navigate('/invite/done')
      }
      setLoading(false)
    }

    return (
      <section className="flex flex-col items-stretch">
        {isDone ? (
          <Done />
        ) : (
          <div className="flex flex-col items-center justify-center pt-12">
            <h1 className="text-3xl font-bold">{t('Create Account')}</h1>
            <Form
              className="w-10/12 lg:w-4/12 py-8"
              onSubmit={onSubmit}
              schema={schema}
              defaultValues={defaultValues}
            >
              <div className="hidden ">
                <Input label={t('Code')} name="activation_key" readOnly />
              </div>
              <div className="mt-5">
                <Input label={t('First Name')} name="first_name" />
              </div>
              <div className="mt-5">
                <Input label={t('Last Name')} name="last_name" />
              </div>
              <div className="mt-5">
                <PasswordInput label={t('Password')} name="password" />
              </div>
              <div className="mt-5">
                <PasswordInput
                  label={t('Confirm Password')}
                  name="confirm_password"
                />
              </div>
              <div className="mt-2.5 w-full flex flex-col items-center">
                <PolicyAndTerms />
              </div>
              <Submit enter loading={loading} primary className="w-full mt-5">
                {t('Create')}
              </Submit>
            </Form>
          </div>
        )}
      </section>
    )
  })
)
