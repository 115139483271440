import {toJS} from 'mobx'
import {inject, observer} from 'mobx-react'
import {StepNavigator} from 'pages/Auth/SignUp/Guard/StepNavigator'
import React from 'react'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {
  CCCVV,
  CCExpire,
  CCNumber,
  countries,
  Divider,
  Form,
  GoBack,
  Input,
  SelectFilter,
  Submit,
} from '@evet/library'
import {PlanCard} from '../PlanCard'
import {StepWrapper} from '../StepWrapper'

import {useLocation, useNavigate} from 'react-router-dom'
import {PolicyAndTerms} from '../../../../components/extras'

export const Step3 = inject(
  'AuthStore',
  'OfferStore',
  'TimezoneStore'
)(
  observer(props => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const data = toJS(props.AuthStore.signupCache)
    const {search} = useLocation()
    //console.log('Step 3', {data})

    const {meta: coefficients, list} = props.OfferStore
    const offers = []
    list.forEach(i => {
      offers.push({
        ...i,
        label: i.offer_name,
        value: i.offer_id,
        price: i.prices[0].price,
        currency_type_cd: i.prices[0].currency_type_cd,
      })
    })

    const schema = Yup.object().shape({
      card_number: Yup.string().required(t('Required')),
      card_expire: Yup.string().required(t('Required')),
      cvc: Yup.string().required(t('Required')),
      card_holder: Yup.string().required(t('Required')),
      // cc_zip_code: Yup.string().required(t('Required')),
      country: Yup.string().required(t('Required')),
      address: Yup.string().required(t('Required')),
      city: Yup.string().required(t('Required')),
      state: Yup.string().required(t('Required')),
      zip_code: Yup.string().required(t('Required')),
    })
    const defaultValues = {
      country: countries[0].value,
      ...data,
    }

    const offset = new Date().getTimezoneOffset() * -1
    const timezones = props.TimezoneStore.list
    const timezone_id =
      (timezones.find(i => i.minute_offset === offset) || {}).value || 7

    const handleSubmit = async _data => {
      const trademarks = data.trademarks.join(',')
      const [expiry_month, expiry_year] = _data.card_expire.split('/')
      const postData = {
        ...data,
        ..._data,
        timezone_id,
        trademarks,
        expiry_month,
        expiry_year,
      }
      // delete postData.country_id
      delete postData.offers
      delete postData.plan
      delete postData.card_expire
      postData.client_type_cd = isNaN(postData.offer_id) ? 'OPM' : 'STANDARD'
      if (await props.AuthStore.createGuard(postData)) {
        props.AuthStore._signupCache = {} // it's for reset data
        props.AuthStore.signupCache = {} // it's for clear localstorage
        if (data.client_type_cd === 'OPM') {
          await navigate('/register/guard/done?custom')
        } else {
          await navigate('/register/guard/done')
        }
      }
    }

    const handleBack = async _data => {
      props.AuthStore.signupCache = {...data, ..._data, offers}
      await navigate('/register/guard/plan' + search)
    }

    //TODO FIXME this is a requirement but if we use this, we will redirect to step 1 when finished signup
    // useEffect(() => {
    //   if (!data.plan) setTimeout(() => navigate('/register' + search), 4000)
    //
    // }, [navigate, data.plan, search])

    return (
      <StepWrapper
        page="CREATE ACCOUNT"
        title={t('Evet Search Compliance & Monitoring')}
        description={t('Start our free 3 day trial')}
      >
        <StepNavigator step={3} />
        <div className="flex-none flex flex-col w-11/12 md:w-10/12 lg:w-8/12 justify-center items-center ">
          <Form
            enter
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            schema={schema}
          >
            <div className="flex flex-col-reverse sm:grid sm:grid-cols-2 gap-5">
              <div className="mb-5">
                <CCNumber
                  label={t('Card Number')}
                  name="card_number"
                  placeholder="XXXX XXXX XXXX XXXX"
                />
                <div className="grid grid-cols-2 gap-4 mt-5">
                  <div>
                    <CCExpire
                      label={t('Expiry Date')}
                      name="card_expire"
                      placeholder="01/28"
                    />
                  </div>
                  <div>
                    <CCCVV label={t('CVV')} name="cvc" placeholder="***" />
                  </div>
                </div>
                <div className="mt-5">
                  <Input label={t('Card Holder')} name="card_holder" />
                </div>
                <div className="pt-10">
                  <Divider title={t('Billing Address')} />
                </div>
                <div className="mt-5">
                  <SelectFilter
                    className="bg-white"
                    label={t('Country')}
                    name="country"
                    options={countries}
                  />
                </div>
                <div className="mt-5">
                  <Input label={t('Address')} name="address" />
                </div>
                <div className="grid grid-cols-3 gap-4 mt-5">
                  <div>
                    <Input label={t('City')} name="city" />
                  </div>
                  <div>
                    <Input label={t('State')} name="state" />
                  </div>
                  <div>
                    <Input label={t('Zip Code')} name="zip_code" />
                  </div>
                </div>
                <div className="mt-2.5 w-full align-middle flex flex-row">
                  <PolicyAndTerms byClicking={t('Create Account')} />
                </div>
                <Submit className="text-sm mt-5 py-2 w-full" primary>
                  {t('Create Account')}
                </Submit>
                <GoBack
                  className="mt-5 mx-auto text-link text-sm"
                  handleBack={handleBack}
                >
                  Go back
                </GoBack>
              </div>
              <div>
                <PlanCard
                  coefficients={coefficients}
                  offers={offers}
                  disabled
                />
              </div>
            </div>
          </Form>
        </div>
      </StepWrapper>
    )
  })
)
