import {inject, observer} from 'mobx-react'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {Validator} from 'rest/helpers/Validations'
import {Form, Input, PasswordInput, Submit} from '@evet/library'
import {Done} from './Done'

export const NewPassword = inject('AuthStore')(
  observer(props => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const params = useParams()
    const {password, confirm_password} = new Validator()
    const schema = Yup.object().shape({
      confirmation_code: Yup.string().required(
        t('Confirmation code is required')
      ),
      password,
      confirm_password,
    })

    const defaultValues = {confirmation_code: params.token}
    const [loading, setLoading] = useState(false)
    const isDone = params.token === 'done'

    const onSubmit = async data => {
      setLoading(true)
      if (await props.AuthStore.newPassword(data)) {
        await navigate('/newpassword/done')
      }
      setLoading(false)
    }

    return (
      <section className="grow w-full flex flex-col items-stretch">
        {isDone ? (
          <Done />
        ) : (
          <div className="flex flex-col items-center justify-center pt-12">
            <h1 className="text-3xl font-bold">{t('Reset Password')}</h1>
            <Form
              className="w-10/12 lg:w-4/12 py-8"
              onSubmit={onSubmit}
              schema={schema}
              defaultValues={defaultValues}
            >
              <div className="hidden">
                <Input label={t('Code')} name="confirmation_code" readOnly />
              </div>
              <div className="">
                <PasswordInput label={t('New Password')} name="password" />
              </div>
              <div className="mt-5">
                <PasswordInput
                  label={t('Confirm New Password')}
                  name="confirm_password"
                />
              </div>
              <Submit enter loading={loading} primary className="w-full mt-6">
                {t('Save')}
              </Submit>
            </Form>
          </div>
        )}
      </section>
    )
  })
)
