import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
// import { MdSecurity } from "react-icons/md";

export const PrivacyPolicy = () => {
  const {t} = useTranslation()

  return (
    <section className="w-full bg-gray-50">
      <h1 className="font-bold text-xl mb-2 text-gray-700 container mx-auto  sm:px-0 px-3 relative">
        <small className="block text-xs text-gray-400 font-light mb-1">
          <Link to="/">Home</Link> / Privacy Policy
        </small>
        Privacy Policy
      </h1>
      <div className="relative bg-white">
        <div className="container mx-auto pt-5 pb-24 sm:px-0 px-3">
          <p className="text-gray-700 font-light">
            {t(
              'This Privacy Policy applies to Evet Technologies, Inc. (hereinafter, “us”, “we”, “Evet” or “Evet.com”).'
            )}{' '}
            {t(
              'We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website.'
            )}{' '}
            {t(
              'We have adopted this privacy policy (“Privacy Policy”) to explain whether we collect data from you' +
                ' as a client or a visitor of our website, how we use this information, and under what circumstances we may disclose the information to third parties.'
            )}
            <br />
            <br />
            {t(
              'Evet may collect information that you provide when you use our subscription services, such as when you'
            )}{' '}
            {t('(1) create an account;')}{' '}
            {t('(2) make a subscription purchase;')}{' '}
            {t('(3) send questions via email etc.')}{' '}
            {t(
              'The types of personal information that you provide may include your first name, last name, e-mail address,' +
                ' phone number, mail address, your company’s or your clients’ official trademark terms, credit card information,' +
                ' and other contact or identifying information that you choose to provide.'
            )}
            <br />
            <br />
            {t(
              'Evet’s servers automatically record certain information about your use of Evet Search Compliance Monitoring solution.'
            )}{' '}
            {t(
              'Similar to other web services, Evet uses cookies, web beacons, and pixel tracking technology' +
                ' to record information such as account activity'
            )}{' '}
            {t(
              '(e.g., storage usage, number of log-ins, actions taken, pages visited)'
            )}{' '}
            {t(', data displayed or clicked on')}{' '}
            {t('(e.g., UI elements, links)')} {t(', other log information')}{' '}
            {t(
              '(e.g., browser type, IP address, date and time of access, cookie ID, referrer URL, etc.)'
            )}{' '}
            {t(', and to remember user preferences while using the services.')}{' '}
            <br />
            <br />
            {t(
              'We may obtain information from other sources and combine that with information we' +
                'collect through our Subscription Services.'
            )}{' '}
            <br />
            <br />
            {t(
              'If you elect to purchase a monthly or annual subscription plan to use Evet Search ' +
                'Compliance Monitoring solution, we may receive information about your purchase ' +
                'from our third-party payment processor, stripe.com.'
            )}
            <br />
            <br />
            {t(
              'We use analytics services and software provided by third parties to help us understand' +
                ' how users access and use the Services. '
            )}{' '}
            {t(
              'These tools and services place cookies, web beacons and other devices or technologies on our Services' +
                ' to enable them to track traffic data.'
            )}{' '}
            {t(
              'The data collected typically includes information such as your IP address, your ISP, your web browser,' +
                ' the time spent on webpages, the links clicked on those pages.'
            )}{' '}
            {t(
              'We use this information to improve our Services and your experience, to see which areas and' +
                ' features of our Services are popular and to count visits.'
            )}{' '}
            <br />
            <br />
            {t(
              'Like most website operators, Evet Technologies Inc. collects non-personally-identifying' +
                ' information of the sort that web browsers and servers typically make available, such as the browser type,' +
                ' language preference, referring site, and the date and time of each visitor request.'
            )}{' '}
            {t(
              "Evet Technologies Inc.'s purpose in collecting non-personally identifying information is to better understand" +
                ' how the site visitors use our website and clients engage with our software applications.'
            )}
            <br />
            <br />
            {t(
              "A cookie is a string of information that a website stores on a visitor's computer, and that" +
                " the visitor's browser provides to the website each time the visitor returns."
            )}{' '}
            {t(
              'Evet uses cookies to help Evet identify and track visitors, their usage of evet.com, and' +
                ' their website access preferences.'
            )}{' '}
            {t(
              'Evet visitors who do not wish to have cookies placed on their computers should set their' +
                ' browsers to refuse cookies before using evet.com website, with the drawback that certain features' +
                " of Evet.com's website may not function properly without the aid of cookies."
            )}{' '}
            {t(
              'By continuing to navigate our website without changing your cookie settings, you hereby acknowledge' +
                " and agree to Evet's use of cookies."
            )}{' '}
            {t(
              'We may use your information to improve our services, send you information about new products,' +
                ' new features, special discount offers, personalize and improve our web applications.'
            )}
            <br />
            <br />
            {t(
              'Clients are asked to submit PII such as first name, last name, email address, client account name,' +
                ' client website URL address, mail address, trademark terms, payment methods etc.'
            )}
            <br />
            <br />
            {t(
              'We use third party analytics software programs like Google Analytics and Facebook Analytics.'
            )}{' '}
            {t(
              'Evet.com uses remarketing services to advertise on third party websites (including Google)' +
                ' to previous visitors to our site.'
            )}{' '}
            {t(
              "It could mean that we advertise to previous visitors who haven't completed a task on our site," +
                ' for example using the free trial sign-up form to complete the trial registration.'
            )}{' '}
            {t(
              'This could be in the form of an advertisement on the Google search results page, or a site in the' +
                ' Google Display Network.'
            )}{' '}
            {t(
              "Third-party vendors, including Google, use cookies to serve ads based on someone's past visits."
            )}{' '}
            {t(
              'Of course, any data collected will be used in accordance with our own privacy policy and' +
                " Google's privacy policy."
            )}
            <br />
            <br />
            {t(
              'You can set preferences for how Google advertises to you using the Google Ad Personalization page.'
            )}{' '}
            {t(
              'And if you want to you can opt out of interest-based advertising entirely by cookie settings or permanently using a browser plugin.'
            )}
            <br />
            <br />
            {t(
              'Evet Technologies Inc. discloses potentially personally-identifying and personally-identifying' +
                ' information only to those of its employees, contractors and affiliated organizations that' +
                ' (i) need to know that information in order to process it on Evet’s, behalf or to provide' +
                ' services available at evet.com website, and (ii) that have agreed not to disclose it to others.'
            )}{' '}
            {t(
              'Some of those employees, contractors and affiliated organizations may be located outside of' +
                " your home country; by using evet.com's website, you consent to the transfer of such information to them."
            )}{' '}
            {t(
              'Evet will not rent or sell potentially personally-identifying and personally-identifying information' +
                ' to anyone.'
            )}{' '}
            {t(
              'Other than to its employees, contractors and affiliated organizations, as described above,' +
                ' Evet Technologies, Inc. discloses potentially personally-identifying and personally-identifying' +
                ' information only in response to a subpoena, court order or other governmental request, or when Evet' +
                ' believes in good faith that disclosure is reasonably necessary to protect the property or' +
                ' rights of Evet Technologies Inc., third parties or the public at large.'
            )}
            <br />
            <br />
            {t(
              'If you are a client of Evet subscription services and a registered user of evet.com and have supplied your email address,'
            )}{' '}
            {t(
              "Evet.com may send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what's going on with Evet Products and Solutions."
            )}
            <br />
            <br />
            {t(
              'General Data Protection Regulation (GDPR) GDPR stands for General Data Protection Regulation, a new privacy law that regulates the processing of personal data relating to individuals in the European Union.'
            )}{' '}
            {t('The GDPR is Europe’s new privacy regulation.')}{' '}
            {t(
              'It gives you control over the data that organizations have about you and how they market to you.'
            )}
            <br />
            <br />
            {t(
              'We already apply strict policies and practices to protect all of our clients’ personal data.'
            )}{' '}
            {t('We don’t serve ads on our own website.')}{' '}
            {t(
              'When we run ads on Facebook, we may use audience data to serve you and people like you ads based on your visits to our sites.'
            )}{' '}
            {t(
              'Facebook’s Data Policy provides much more information on how your Facebook data is used on their platform.'
            )}{' '}
            {t(
              'We use Mailchimp.com to send email messages, and we’ll work with them in removing your email address if you decide to unsubscribe from our emails (there’s an unsubscribe link in every generic marketing emails).'
            )}{' '}
            {t(
              'Those who engage in transactions with Evet Technologies, Inc. – by purchasing Evet’s subscription products, are asked to provide additional information, including as necessary the personal and financial information required to process those transactions.'
            )}{' '}
            {t(
              'All credit card processing is done with Stripe via API and we never see your data.'
            )}
            <br />
            <br />
            {t(
              'The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure.'
            )}{' '}
            {t(
              'While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.'
            )}{' '}
            <br />
            <br />
            {t('You may terminate your use of Evet any time.')}{' '}
            {t(
              'If you have created an account, you may at any time review and/or update the contact information we have for you.'
            )}{' '}
            {t(
              'Please note that even if you delete information from your account, or deactivate it, we may retain certain information as required by law or for legitimate business purposes.'
            )}{' '}
            {t(
              'We may also retain cached or archived copies of your information for a certain period of time.'
            )}{' '}
            {t('Most web browsers are set to accept cookies by default.')}{' '}
            {t(
              'If you prefer, you can usually choose to set your browser to remove or reject browser cookies.'
            )}{' '}
            {t(
              'You may ask for a copy of the personal data we’ve collected about you.'
            )}{' '}
            {t('You can unsubscribe from direct marketing newsletters.')}
            <br />
            <br />
            {t(
              'We may amend or update this Privacy Statement at any time and will indicate when changes have been made by revising the date at the top of this Policy.'
            )}{' '}
            {t(
              'You can review the most current version of this Privacy Statement at any time at evet.com/privacy-policy.'
            )}
            <br />
            <br />
            {t(
              'If You have questions or complaints regarding Evet’s Privacy Statement or practices, please contact Us at legal@evet.com'
            )}
          </p>
          <br />
          <br />
          <span className="font-bold">{t('Evet Team')}</span>
          <br />
          <span className="font-bold text-primary">
            {t('Evet Technologies, Inc.')}
          </span>
        </div>
      </div>
    </section>
  )
}

