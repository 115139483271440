import React from 'react'
import {useTranslation} from 'react-i18next'

export const StepNavigator = ({step}) => {
  const {t} = useTranslation()
  return (
    <div className="flex justify-between items-center -mt-7 mb-5 text-center sm:text-left">
      <div
        className={
          'flex flex-col sm:flex-row justify-start items-center border py-2  px-6  ' +
          ' rounded-l-md ' +
          (step === 1 ? ' bg-white ' : 'bg-gray-100 ')
        }
      >
        <i className="icon-user text-2xl sm:mr-3" />
        <div className="flex flex-col">
          <span className="text-xs hidden sm:block text-gray">
            {t('Step 1')}
          </span>
          <p className="text-sm text-center sm:text-left">
            {t('Create Account')}
          </p>
        </div>
      </div>
      <div
        className={
          'flex flex-col sm:flex-row justify-start items-center border py-2  px-6  ' +
          'rounded-r-md ' +
          (step === 2 ? ' bg-white ' : 'bg-gray-100 ')
        }
      >
        <i className="icon-credit-card text-2xl sm:mr-3" />
        <div className="flex flex-col">
          <span className="text-xs hidden sm:block text-gray">
            {t('Step 2')}
          </span>
          <p className="text-sm text-center sm:text-left">
            {t('Promotion Method')}
          </p>
        </div>
      </div>
    </div>
  )
}
