import React from 'react'

export const StepWrapper = ({
  children,
  page,
  title,
  description,
  className,
}) => (
  <div className="flex-none flex flex-col items-center justify-center">
    <div
      className={`bg-gray-100 flex flex-col w-full justify-start items-center pt-6 pb-14 ${className}`}
    >
      <h3 className="relative text-sm text-primary font-medium">{page}</h3>
      <h1 className="relative text-3xl font-bold text-center mt-3">{title}</h1>
      <p
        className="relative text-sm text-center mb-6 mt-6 mx-10
       w-11/12 md:w-10/12 lg:w-8/12"
      >
        {description}
      </p>
    </div>

    {children}
  </div>
)
