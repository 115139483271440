import {action, makeObservable, observable, runInAction, toJS} from 'mobx'
import {Account as Model} from 'rest/models'
import {AccountServices as Service} from 'rest/services'
import {request} from 'rest/helpers'
import {v4 as uuid} from 'uuid'
import cookie from 'react-cookies'
import jwt_decode from 'jwt-decode'

export default class AuthStore {
  authenticated = null
  uid = null
  me = null
  remember = false
  isCorrectEnvironment = true

  constructor(stores) {
    this.stores = stores
    const token = cookie.load('access')
    this.authenticated = !!cookie.load('refresh')
    this.token = token ? jwt_decode(token) : {}
    this.isCorrectEnvironment =
      !this.token.runtime_environment ||
      this.token.runtime_environment === process.env.REACT_APP_ENV
    this.me = token ? new Model(this.token.user) : null

    makeObservable(this, {
      uid: observable,
      authenticated: observable,
      me: observable,
      _signupCache: observable,
      isCorrectEnvironment: observable,

      resetSignupCache: action,
      createGuard: action,
      signIn: action,
      handleAuth: action,
      changePassword: action,
      signOut: action,
      createPublisher: action,
      checkEmail: action,

      handleError: action.bound,
    })
  }

  _signupCache = JSON.parse(localStorage.getItem('_signupCache'))

  get signupCache() {
    const data = (this._signupCache || {}).sessionID
      ? this._signupCache
      : JSON.parse(localStorage.getItem('_signupCache'))
    return toJS(data || {})
  }

  createPublisher = async data => {
    return await Service.createPublisher(data).then(
      res => res,
      this.handleError
    )
  }

  set signupCache(_data) {
    const cache = this._signupCache || {}
    const data =
      cache.sessionID && cache._expire > new Date().getTime()
        ? this._signupCache
        : {
            sessionID: uuid(),
            _expire: Number(new Date().getTime()) + 7200000,
          }
    this._signupCache = {...data, ..._data}
    localStorage.setItem('_signupCache', JSON.stringify(this._signupCache))
  }

  resetSignupCache = () => {
    this._signupCache = {}
  }

  checkEmail = async data => {
    return await Service.checkEmail(data).then(res => res, this.handleError)
  }

  createGuard = async data => {
    if (!data) return false
    return await Service.createGuard(data).then(res => res, this.handleError)
  }

  signIn = async ({email, password, remember}) => {
    if (!email || !password) return false
    request.deleteCookies()
    const res = await Service.signIn({email, password, remember}).then(
      res => res,
      this.handleError
    )
    if (!res) return
    request.setCookies({...res, remember})
    const token = cookie.load('access')
    runInAction(() => {
      this.me = new Model(token ? jwt_decode(token).user : {})
      this.authenticated = true
    })
    return this.authenticated
  }

  handleAuth = async () => {
    if (this.authenticated && this.me && this.me.id) return
    // console.log('handleAuth')
    const token = await request.token
    const data = token ? jwt_decode(token) : {}
    runInAction(() => {
      this.signupCache = {}
      this.authenticated = !!token
      this.me = token ? new Model(data.user) : false
    })
    return this.authenticated
  }

  reset = async ({email}) => {
    if (!email) return false
    return await Service.reset({email}).then(res => res, this.handleError)
  }

  newPassword = async ({confirmation_code, password}) => {
    if (!confirmation_code) return false
    return await Service.newPassword({confirmation_code, password}).then(
      res => res,
      this.handleError
    )
  }

  changePassword = async ({password, new_password}) => {
    const access_token = await request.access_token
    return await Service.changePassword({
      password,
      new_password,
      access_token,
    }).then(() => true, this.handleError)
  }

  signOut = async (from = null) => {
    this.me = false
    this.authenticated = false
    Service.signOut(from)
  }

  activation = async data => {
    if (!data) return false
    return await Service.activation(data).then(res => res, this.handleError)
  }

  verify = async data => {
    if (!data) return false
    return await Service.verify(data).then(res => res, this.handleError)
  }

  newsletter = async data => {
    if (!data) return false
    return await Service.newsletter(data).then(res => res, this.handleError)
  }

  // validate = async data => {
  //   return await Service.validate(data).then(res => res, this.handleError)
  // }

  signupCacheClear = () => {
    localStorage.removeItem('_signupCache')
    this._signupCache = null
    this.signupCache = {}
  }

  handleError = error => {
    // this.state = "error";
    return this.stores.SystemMessageStore.handleError(error)
  }
}
