import {action, computed, makeObservable, observable, toJS} from 'mobx'
import {CommonServices as Service} from '../services'
import {OfferMeta as Meta} from '../models'

export default class OfferStore {
  state = 'pending'
  keywordsState = 'pending'
  _list = []
  _keywords = []
  _meta = {}

  constructor(Stores) {
    this.stores = Stores

    makeObservable(this, {
      _list: observable,
      _keywords: observable,
      _meta: observable,
      state: observable,
      keywordsState: observable,

      list: computed,
      meta: computed,

      read: action,
      readKeywords: action,

      fetchSuccess: action.bound,
      fetchError: action.bound,
    })
  }

  read() {
    if (this.state === 'listing' || this.state === 'done') return
    this._list = []
    this.state = 'listing'
    Service.offers().then(this.fetchSuccess, this.fetchError)
  }

  readKeywords({params}) {
    this._keywords = []
    this.keywordsState = 'listing'
    Service.readKeywords({params}).then(
      res => (this._keywords = (res.item || {}).keywords || []),
      this.fetchError
    )
  }

  fetchSuccess = res => {
    this._list = res.items
    this._meta = new Meta(res.meta || {})
    this.state = 'done'
  }

  fetchError = error => {
    this.state = 'error'
    return this.stores.SystemMessageStore.handleError(error)
  }

  get list() {
    return toJS(this._list)
  }

  get keywords() {
    return toJS(this._keywords)
  }

  get meta() {
    return toJS(this._meta)
  }
}
