import {inject, observer} from 'mobx-react'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link, useLocation} from 'react-router-dom'
import * as Yup from 'yup'
import {
  CheckBox,
  Form,
  Input,
  PasswordInput,
  qs,
  routeLocal,
  Submit,
} from '@evet/library'
import {Validator} from 'rest/helpers/Validations'
import {origin} from 'rest/helpers'

export const SignIn = inject('AuthStore')(
  observer(props => {
    const {search} = useLocation()
    const {email, password, remember} = new Validator()
    const {t} = useTranslation()

    const schema = Yup.object().shape({
      email,
      password,
      remember,
    })

    // const {me} = props.AuthStore
    // useEffect(() => {
    //   if (me && me.id) navigate('/dashboard')
    // }, [me, navigate])

    const handleSubmit = async data => {
      const {from} = qs.parse(search)
      if (await props.AuthStore.signIn(data)) {
        const {me} = props.AuthStore
        if (me.products.length <= 1) {
          if (process.env.NODE_ENV === 'production') {
            // if only if the user has single product will be redirected
            setTimeout(
              () => (window.location.href = from || me.product.url),
              500
            )
          }
          //process.env.REACT_APP_MAIN_PROJECT
          else {
            setTimeout(
              () =>
                (window.location.href =
                  from ||
                  routeLocal(me.product).replace('http://localhost', origin)),
              500
            )
          }
        } else if (me && me.id) {
          window.location.href = from || '/dashboard'
        } //await navigate('/dashboard')
      }
    }

    return (
      <section className="w-full ">
        <div className="flex flex-col items-center justify-center pt-12">
          <h1 className="text-3xl font-bold">{t('Sign in to')}</h1>
          <p className="text-center text-sm underline text-primary font-regular pt-4">
            {t('Evet Search Compliance & Monitoring')}
          </p>
          <Form
            schema={schema}
            onSubmit={handleSubmit}
            className="w-10/12 lg:w-4/12 py-8"
          >
            <div className="mb-4">
              <Input
                label="E-mail"
                name="email"
                placeholder="jon@doe.com"
                className="text-gray-800 "
              />
            </div>
            <div className="mb-4">
              <PasswordInput
                label="Password"
                name="password"
                placeholder="your strong password"
                className="text-gray-800 "
              />
            </div>
            <div className="flex justify-between">
              <CheckBox label="Remember me" name="remember" />
              <Link className="text-primary mb-1 text-sm" to="/reset">
                Forgot Password
              </Link>
            </div>
            <Submit className="mt-3 w-full p-2" primary>
              Sign In
            </Submit>
          </Form>
          <div className="w-full flex justify-center items-center">
            <p className="text-sm">{t("Don't have an account?")}</p>
            {/*FIXME production control*/}
            <Link
              data-cy="sign-up-bottom"
              className="text-sm text-primary ml-4"
              to={
                '/register' +
                (process.env.NODE_ENV === 'production' ? '/guard' : '')
              }
            >
              {t('Sign up')}
            </Link>
          </div>
        </div>
      </section>
    )
  })
)
