import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

export const DoneCustom = () => {
  const {t} = useTranslation()

  return (
    <React.Fragment>
      <h1 className="font-semibold text-3xl text-gray-700">
        {t('Application Pending')}
      </h1>
      <h5 className="text-sm w-3/5 text-center mt-5 text-primary">
        {t('Thanks for completing your profile.')}
      </h5>
      <p className="w-3/5 text-xs text-center mt-8 text-gray-800">
        <Trans>
          Our account managers will review your application and you will receive
          an email <br /> as soon as your account is approved.
        </Trans>
      </p>
    </React.Fragment>
  )
}
