import {inject, observer} from 'mobx-react'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {useLocation, useNavigate} from 'react-router'
import * as Yup from 'yup'
import {Validator} from 'rest/helpers/Validations'
import {Form, Input, Submit} from '@evet/library'
import {Done} from './Done'

const leftIcon = <i className="mr-1 p-0 icon-left-dir" />

export const Reset = inject('AuthStore')(
  observer(props => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {email} = new Validator()
    const schema = Yup.object().shape({email})
    const [loading, setLoading] = useState(false)
    //const location = localStorage.getItem('location')

    useEffect(() => {
      if (props.AuthStore.authenticated) {
        navigate('/')
      }
    }, [navigate, props.AuthStore.authenticated])

    const {search} = useLocation()
    const isDone = search === '?done'

    const onSubmit = async data => {
      setLoading(true)
      if (await props.AuthStore.reset(data)) {
        await navigate('/reset?done')
      }
      setLoading(false)
    }

    return (
      <section className="grow w-full flex flex-col items-stretch">
        {isDone ? (
          <Done />
        ) : (
          <div className="flex flex-col items-center justify-center pt-12">
            <h1 className="text-3xl font-bold">{t('Reset Your Password')}</h1>
            <p className="text-center text-sm underline text-primary font-regular pt-4 px-4">
              {t('We’ll email you instructions to reset your password.')}
            </p>
            <Form
              onSubmit={onSubmit}
              schema={schema}
              className="w-10/12 lg:w-4/12 py-8"
            >
              <div className="">
                <Input
                  placeholder="Email"
                  className=" rounded-md text-sm bg-gray-50 focus:bg-gray-100 focus:ring-0 focus:border-primary w-full "
                  name="email"
                />
              </div>
              <Submit loading={loading} enter primary className="w-full mt-6">
                {t('Reset Password')}
              </Submit>
            </Form>
            <div className="flex justify-center items-center mt-6">
              <Link
                className="text-sm flex justify-center items-center pr-3"
                to="/login"
              >
                {leftIcon}
                <p>{t('Go back')}</p>
              </Link>
            </div>
          </div>
        )}
      </section>
    )
  })
)
