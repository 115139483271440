import {inject, observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Link, useLocation} from 'react-router-dom'
import {qs} from '@evet/library'

export const SignOut = inject('AuthStore')(
  observer(props => {
    const {t} = useTranslation()
    const {search} = useLocation()
    const {from} = qs.parse(search)

    useEffect(() => {
      props.AuthStore.signOut(from).then()
    }, [props.AuthStore, from])

    return (
      <section className="h-100 m-h-auto">
        <div className="full-box p-20">
          <div className="items-center text-center p-20">
            {t('Sorry to see you go ... ')}
            <Link to={'/login?from=' + from}>{t(' back to login')}</Link>
          </div>
        </div>
      </section>
    )
  })
)
