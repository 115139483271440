import {inject, observer} from 'mobx-react'
import React, {useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {
  countries,
  Form,
  Input,
  PasswordInput,
  qs,
  SelectFilter,
  Submit,
} from '@evet/library'
import {Validator} from 'rest/helpers/Validations'
import {CommonServices as Services} from 'rest/services'
import {PolicyAndTerms} from 'components/extras'
import {StepNavigator} from '../StepNavigator'
import {StepWrapper} from '../../StepWrapper'

export const Step1 = inject(
  'AuthStore',
  'OfferStore',
  'SystemMessageStore'
)(
  observer(props => {
    const {
      name,
      // family_name,
      email,
      weak_password: password,
      phone: phone_number,
      // company_url,
      requiredString,
    } = new Validator()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {search} = useLocation()
    const params = qs.parse(search)
    const meta = props.OfferStore.meta
    const data = props.AuthStore.signupCache

    const [networkCountries, setNetworkCountries] = useState([])

    useEffect(() => {
      Services.NetworkCountries()
        .then(result =>
          setNetworkCountries(
            result.items.map(i => ({value: i.country_code, label: i.name}))
          )
        )
        .catch(err => {
          setNetworkCountries([])
          props.SystemMessageStore.handleError(err)
        })
      // eslint-disable-next-line
    }, [])

    //TODO VALIDATE
    const schema = Yup.object().shape({
      name,
      contact: Yup.object({
        first_name: Yup.string().required(t('Required')),
        last_name: Yup.string().required(t('Required')),
        email,
        phone: phone_number,
        address: requiredString,
        country: Yup.string().required(t('Required')),
        // zip_code: Yup.string().required(t('Required')),
        // zip_code: requiredString,
      }),
      primary_region: Yup.string().required(t('Required')),
      password,
    })

    const defaultValues =
      process.env.NODE_ENV === 'production'
        ? {
            // email: props.AuthStore.signupCache.email || '',
            ...data,
            plan: params.plan || data.plan || meta.defaultPlan,
          }
        : {
            // name: 'company_name',
            // primary_region: 'US',
            // password: 'burak1994',
            // contact: {
            //   first_name: 'first name',
            //   last_name: 'last_name',
            //   email: 'burak+authregistertest@evet.com',
            //   phone: '12312312312',
            //   address: 'akhsdf ajsdhfuhsdf asidhfoahfs asdhfsdf',
            //   zip_code: 'YO1 JJ0',
            //   country: 'UK',
            // },
            // publisher: {
            //   service_areas: ['US', 'UK', 'CA'],
            //   categories: [1, 2, 3, 4],
            //   uri: 'evet.com',
            //   types: [1, 2],
            // },
            ...data,
            plan: params.plan || data.plan || meta.defaultPlan,
          }

    const handleSubmit = async _data => {
      console.log({_data})
      if (await props.AuthStore.checkEmail(_data.contact)) {
        props.AuthStore.signupCache = {...data, ..._data}
        await navigate(
          '/register/network/publisher/promotion?' + qs.stringify(params)
        )
      }
    }

    const {resetSignupCache, authenticated} = props.AuthStore

    useEffect(() => {
      if (!data.sessionID || data._expire < new Date().getTime()) {
        resetSignupCache()
      }
    }, [resetSignupCache, data.sessionID, data._expire])

    useEffect(() => {
      ;(async () => {
        if (authenticated) {
          await navigate('/')
        }
      })()
    }, [navigate, authenticated])

    return (
      <StepWrapper
        page="PUBLISHER"
        title={t('Become a Evet Publisher')}
        className=""
        description={
          <Trans>
            Sign up now and start promoting the world’s top brands via Evet’s
            Global Affiliate Marketing Platform.
            <br />
            Already a Evet Publisher? Log in.
          </Trans>
        }
      >
        {/*<meta*/}
        {/*  title="Publisher Sign-up | Evet Affiliate Network"*/}
        {/*  content="Sign-up today to Join the Evet Affiliate Network. Once we approve your affiliate account application, you will gain access to Evet’s Global Affiliate Platform, where you can start promoting advertisers’ affiliate programs and start monetizing your websites."*/}
        {/*/>*/}

        <div className="flex flex-col items-center w-11/12 md:w-10/12 lg:w-8/12">
          <StepNavigator step={1} />
          <div className="flex-none flex flex-col sm:flex-row justify-between w-full">
            <div className="p-4 w-full sm:w-6/12 mr-4">
              <Form
                onSubmit={handleSubmit}
                schema={schema}
                defaultValues={defaultValues}
              >
                <div className="mt-5 ">
                  <Input
                    label={t('Company Name')}
                    name="name"
                    placeholder="Evet Inc."
                  />
                </div>
                <div className="mt-5 ">
                  <SelectFilter
                    className="bg-white selectRegion"
                    options={networkCountries}
                    label={t('Primary Region')}
                    name="primary_region"
                  />
                </div>
                <div className="mt-5 ">
                  <Input
                    label={t('First Name')}
                    name="contact.first_name"
                    placeholder="Jon"
                    className="text-sm"
                  />
                </div>
                <div className="mt-5 ">
                  <Input
                    label={t('Last Name')}
                    name="contact.last_name"
                    placeholder="Doe"
                  />
                </div>
                <div className="mt-5 ">
                  <PasswordInput
                    label={t('Password')}
                    name="password"
                    placeholder="password"
                  />
                </div>
                <div className="mt-5 ">
                  <Input
                    label={t('Email')}
                    name="contact.email"
                    placeholder="jon@company.tld"
                  />
                </div>
                <div className="mt-5 ">
                  <Input label={t('Phone')} name="contact.phone" />
                </div>
                <div className="grid grid-cols-12 gap-4  mt-5 ">
                  <div className="col-span-8">
                    <SelectFilter
                      className="bg-white selectCountry"
                      label={t('Country')}
                      name="contact.country"
                      options={countries}
                    />
                  </div>
                  <div className="col-span-4">
                    <Input label={t('Zip Code')} name="contact.zip_code" />
                  </div>
                </div>
                <div className="mt-5 ">
                  <Input label={t('Address')} name="contact.address" />
                </div>
                <div className="mt-2.5 w-full flex flex-col items-center">
                  <PolicyAndTerms byClicking={t('Continue')} />
                </div>
                <Submit className="text-sm mt-5 py-2 w-full" primary>
                  {t('Continue')}
                </Submit>
              </Form>

              <div className="flex-none flex flex-row items-center justify-center w-full mt-8 mb-16">
                <p className="font-light text-sm">
                  {t('Already have an account?')}
                </p>
                <Link
                  className="font-normal text-sm text-primary underline pl-1.5"
                  to="/login"
                >
                  {t('Sign in')}
                </Link>
              </div>
            </div>
            <div className="sm:border-l" />
            <div className="p-4 w-full sm:w-6/12 sm:ml-4 pb-24 sm:pb-0">
              <p className="text-xl font-medium">What is a Evet Publisher?</p>
              <p className="text-xs font-light mt-2">
                {t(
                  'Evet Affiliate Network gives you access to 20,000 Affiliate Programs across clothing, travel, beauty, home goods, computers, electronics, and more all in one place.'
                )}
              </p>
            </div>
          </div>
        </div>
      </StepWrapper>
    )
  })
)
