import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Button, Dialog} from '@evet/library'

export const ExampleKeywordButton = () => {
  const {t} = useTranslation()
  const [open, setOpen] = useState(false)
  return (
    <React.Fragment>
      <Button
        style={{display: 'inline-block'}}
        className="basic-button text-link text-sm font-normal"
        onClick={() => setOpen(true)}
      >
        {t('example of a keyword list. ')}
      </Button>
      <Dialog
        open={open}
        onClose={() => setTimeout(() => setOpen(false), 20)}
        icon={<i className="icon-tags" />}
        cancel={t('Close')}
        title={t('Keyword List Example')}
        description={
          <div className="flex flex-col w-full">
            <div className="flex flex-col justify-center items-start full m-4">
              <p className="font-light">
                {t('Client')}:<span className="font-normal"> macys.com</span>
              </p>
              <p className="font-light">
                {t('Branded Terms(s)')} :{' '}
                <span className="font-normal">macys, macys.com</span>
              </p>
            </div>
            <div className="text-md font-bold text-black text-center text-left">
              {t('Keywords')}
            </div>
            <div className="text-xs overflow-x-auto h-28  mt-2 border flex flex-wrap justify-center items-start">
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                macys coupon
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                macys.com coupon
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                coupon macys
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                coupon macys.com
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                macys coupon code
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                macys.com coupon code
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                coupon code macys
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                coupon code macys.com
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                macys promo code
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                macys.com promo code
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                promo code macys
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                promo code macys.com
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                macys coupons
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                macys.com coupons
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                coupons macys
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                coupons macys.com
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                macys discount
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                macys.com discount
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                discount macys
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                discount macys.com
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                macys discounts
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                macys.com discounts
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                discounts macys
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                discounts macys.com
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                macys discount code
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                macys.com discount code
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                discount code macys
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                discount code macys.com
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                macys couponcode
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                macys.com couponcode
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                couponcode macys
              </div>
              <div className="m-1 p-1 bg-gray-200 rounded-md shadow-sm">
                couponcode macys.com
              </div>
            </div>
          </div>
        }
      />
    </React.Fragment>
  )
}
