import {inject, observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {Outlet} from 'react-router-dom'

export const SignUp = inject('OfferStore')(
  observer(props => {
    useEffect(() => {
      props.OfferStore.read()
      // eslint-disable-next-line
    }, [])
    return <Outlet />
  })
)
