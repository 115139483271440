import {inject, observer} from 'mobx-react'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router'
import {
  Form,
  GoBack,
  IncrementalInput,
  qs,
  Select,
  SelectFilter,
  Slider,
  Submit,
  useFormContext,
} from '@evet/library'
import * as Yup from 'yup'
import {CommonServices as Services} from 'rest/services'
import {PolicyAndTerms} from 'components/extras'
import {StepWrapper} from '../StepWrapper'
import {StepNavigator} from './StepNavigator'
import {PlanCard} from '../PlanCard'
import {getFrequencyProps} from '../CalcFunctions'

const CustomFields = props => {
  const {watch} = useFormContext()
  const {t} = useTranslation()
  const coefficients = props.coefficients
  const frequency = watch('frequency')
  const frequencyProps = getFrequencyProps(frequency, coefficients)
  const {min_frequency: frequencyMin = 0, max_frequency: frequencyMax = 1000} =
    coefficients || {}
  const {keyword_min: keywordMin = 0, keyword_max: keywordMax = 1000} =
    frequencyProps || {}

  return (
    watch(props.watch) === 'custom' && (
      <div className="horizon between per-100 mt-4">
        <Slider
          label={t('Daily Frequency')}
          info={t(
            'This is how frequently you want your Branded keyword terms to be monitored on a daily basis by our automated monitoring system.'
          )}
          min={frequencyMin}
          max={frequencyMax}
          name="frequency"
          className="frequency per-45"
          marks
        />
        <Slider
          label={t('Keyword')}
          info={t(
            "This is how many keyword variations you want our automated solution technology to monitor your company's Branded keyword terms. "
          )}
          min={keywordMin}
          max={keywordMax}
          step={1}
          name="keyword_limit"
          className="keyword_limit per-45"
          marks
        />
      </div>
    )
  )
}

export const Step2 = inject(
  'AuthStore',
  'CountryStore',
  'OfferStore'
)(
  observer(props => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {search} = useLocation()
    const params = qs.parse(search)
    const data = props.AuthStore.signupCache

    const schema = Yup.object().shape({
      trademarks: Yup.array().of(
        Yup.string().min(3, t('Trademark must be at least 3 characters'))
      ),
      plan: Yup.string().required(t('Required')),
      country_id: Yup.string().required(t('Required')),
    })

    const {meta: coefficients, list} = props.OfferStore
    const offers = []
    list.forEach(i => {
      offers.push({
        ...i,
        label: i.offer_name,
        value: i.offer_id,
        price: i.prices[0].price,
        currency_type_cd: i.prices[0].currency_type_cd,
        keyword_limit: i.settings.keywords_count,
        frequency: i.settings.frequency,
      })
    })

    const defaultValues = {
      ...data,
      keyword_limit: data.keyword_limit || 150,
      frequency: data.frequency || 12,
      trademarks: data.trademarks || [],
      country_id: (data.country_id || 1).toString(),
      plan: (
        data.plan ||
        params.plan ||
        coefficients.default_plan ||
        'custom'
      ).toString(), //TODO coeff default plan couldt set as default
      is_annual: data.is_annual || 'false',
      // trademarks: ['trademark'],
    }

    const [guardCountries, setGuardCountries] = useState([])

    useEffect(() => {
      Services.GuardCountries().then(result =>
        setGuardCountries(
          result.items.map(i => ({value: i.country_id, label: i.country_name}))
        )
      )
    }, [])

    const handleSubmit = async _data => {
      _data['trademarks'] = _data['trademarks'].filter(i => i && i !== '')
      const isAPlan = coefficients.plans
        ? coefficients.plans[_data.frequency + '-' + _data.keyword_limit]
        : false

      props.AuthStore.signupCache =
        isAPlan || _data.plan !== 'custom'
          ? {
              ...data,
              ..._data,
              account_type: 'STANDARD',
              offer_id: isAPlan || _data.plan,
              offers,
            }
          : {...data, ..._data, account_type: 'OPM', offers}

      await navigate('/register/guard/payment?' + qs.stringify(params))
    }
    const handleBack = async _data => {
      _data['trademarks'] = _data['trademarks'].filter(i => i && i !== '')
      props.AuthStore.signupCache = {...data, ..._data, offers}
      await navigate(
        '/register/guard' + qs.stringify(params, {addQueryPrefix: true})
      )
    }

    return (
      <StepWrapper
        page="CREATE ACCOUNT"
        title={t('Evet Search Compliance & Monitoring')}
        description={t('Start our free 3 day trial')}
      >
        <StepNavigator step={2} />
        <div className="flex-none flex flex-col w-11/12 md:w-10/12 lg:w-8/12">
          <Form
            enter
            onSubmit={handleSubmit}
            schema={schema}
            defaultValues={defaultValues}
          >
            <div className="flex flex-col-reverse sm:grid sm:grid-cols-2 gap-5">
              <div className="mb-5">
                <Select
                  className="bg-white"
                  label={t('Select Plan')}
                  name="plan"
                  options={[
                    ...offers,
                    // {
                    //   label: 'Custom',
                    //   value: 'custom',
                    // },
                  ]}
                />
                <CustomFields coefficients={coefficients} watch="plan" />
                <div className="mt-5">
                  <IncrementalInput
                    label={t('Add your Branded Term(s) ')}
                    icon={<i className="icon-tag" />}
                    name="trademarks"
                    max={3}
                    placeholders={[t('e.g., macys'), t('e.g., macys.com')]}
                  />
                </div>
                <div className="mt-5">
                  <SelectFilter
                    className="country bg-white"
                    label={t('Choose Country')}
                    info={t(
                      'This feature allows you to choose which Country you want our team to be monitoring when looking for "Unauthorized" Bidders.'
                    )}
                    name="country_id"
                    options={guardCountries}
                  />
                </div>
                <div className="mt-2.5 w-full align-middle flex flex-row">
                  <PolicyAndTerms byClicking={t('Continue')} />
                </div>
                <Submit className="text-sm mt-5 py-2 w-full" primary>
                  {t('Continue')}
                </Submit>
                <GoBack
                  className="mt-5 mx-auto text-link text-sm"
                  handleBack={handleBack}
                >
                  Go back
                </GoBack>
              </div>
              <div>
                <PlanCard coefficients={coefficients} offers={offers} />
              </div>
            </div>
          </Form>
        </div>
      </StepWrapper>
    )
  })
)
