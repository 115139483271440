import {inject, observer} from 'mobx-react'
import React from 'react'
import {SystemMessages as EvetSystemMessages} from '@evet/library'

export const SystemMessages = inject('SystemMessageStore')(
  observer(props => {
    const messages = props.SystemMessageStore.news
    return <EvetSystemMessages messages={messages} />
  })
)
