import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {Radio, useFormContext} from '@evet/library'
import {ExampleKeywordButton} from './ExampleKeywordButton'
import {Currency} from 'components'
import {getFrequencyProps, getKeywordProps, priceCalc} from './CalcFunctions'

export const PlanCard = props => {
  const {t} = useTranslation()

  const {watch} = useFormContext()
  const {offers, coefficients} = props
  const plan = watch('plan') || props.plan

  const frequency = watch('frequency')
  const frequencyProps = getFrequencyProps(frequency, coefficients)
  const keywords = watch('keyword_limit')
  const keywordProps = frequencyProps
    ? getKeywordProps(keywords, frequencyProps)
    : {}
  const price = priceCalc(
    frequency,
    frequencyProps.frequency_coefficient,
    keywords,
    keywordProps
  )
  const annuallyDiscount = price =>
    Math.ceil(price * (coefficients.annual_price_ratio || 1))

  const isAPlan = coefficients.plans
    ? coefficients.plans[frequency + '-' + keywords]
    : false
  const selectedPlan = (plan === 'custom'
    ? isAPlan
      ? offers.find(i => String(i.value) === String(isAPlan))
      : {
          label: t('Custom'),
          offer_description: t('Custom Plan'),
          descriptions: [
            t('{{keywords}} branded keyword variations', {keywords}),
            t('{{frequency}}x Daily Monitoring Frequency', {frequency}),
          ],
          price,
        }
    : offers.find(i => String(i.value) === String(plan))) || {
    descriptions: [],
  }

  return (
    <div data-cy="PlanCard">
      <div className="bg-white border p-4 mb-4">
        <p className="text-sm text-primary">{t('Your Current Plan')}</p>
        <p className="font-bold text-3xl mt-2.5 ">{selectedPlan.label}</p>
        <p className="font-light text-sm mt-2.5">
          {/* Must-have plan for small sized companies and new associate
              programs. */}
          {selectedPlan.offer_description}
        </p>
        <div className="flex justify-between items-center">
          <div className="flex justify-start">
            <Radio
              label={
                <div className="ml-4 mt-4 mb-4">
                  <p className="text-3xl font-bold text-black whitespace-nowrap">
                    <Currency>{selectedPlan.price}</Currency>
                  </p>
                  <p className="text-xs">
                    {t('Monthly')}
                    <br />
                    {t('(Paid Monthly)')}
                  </p>
                </div>
              }
              name="is_annual"
              value={false}
              disabled={props.disabled}
            />
          </div>
          <div className="flex justify-start">
            <Radio
              label={
                <div className="ml-4 mt-4 mb-4">
                  <p className="text-3xl font-bold text-black">
                    <Currency>{annuallyDiscount(selectedPlan.price)}</Currency>
                  </p>
                  <p className="text-xs">
                    {t('Monthly')}
                    <br />
                    {t('(Paid Annually)')}
                  </p>
                </div>
              }
              name="is_annual"
              value={true}
              disabled={props.disabled}
            />
          </div>
        </div>
        <ul className="text-xs font-light leading-normal list-disc pl-4">
          {selectedPlan.descriptions.map(i => (
            <li key={'li-' + i}>{i}</li>
          ))}
        </ul>
      </div>
      <div className="bg-white border p-4 mb-4 font-roboto text-sm font-light  ">
        {/* <Trans>
          {t(
            'You are signing up for a 3-day free trial. Your free trial starts once the account is approved by Evet. You will not be charged until your free trial period ends. Your subscription will continue until you cancel it, which can be done at any time. Welcome to Evet!'
          )}
        </Trans> */}
        <Trans>
          {t('Our experienced search team will create a list of high-volume') +
            ' '}
        </Trans>

        {t('keyword terms including your branded terms. ')}

        {t(
          'These keywords will be monitored upon approval of your free trial account. See an '
        )}
        <ExampleKeywordButton />
        {t(
          ' You will be able to edit, remove, add keyword terms in your own dashboard.'
        )}
      </div>
    </div>
  )
}
