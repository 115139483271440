import React from 'react'
import {CustomHeader, SystemMessages} from 'components'
import {HelmetHead} from './HelmetHead'
import {Layout, Version} from '@evet/library'
import packageJSON from '../../package.json'

const {dependencies, version} = packageJSON

export const LayoutPrivate = ({title, description, children}) => (
  <React.Fragment>
    <HelmetHead title={title} description={description} />
    <SystemMessages />
    <Layout className="bg-gray-100 min-h-screen flex flex-col items-stretch ">
      <CustomHeader />
      {children}
      <Version
        version={version}
        title={
          'evet-library: v' +
          (dependencies['@evet/library'] || '#').split(/#/)[1]
        }
      />
    </Layout>
  </React.Fragment>
)
