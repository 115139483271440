import React from 'react'
import {useTranslation} from 'react-i18next'

export const DoneCustom = () => {
  const {t} = useTranslation()

  return (
    <React.Fragment>
      <h1 className="font-semibold text-3xl text-gray-700">{t('Thanks!')}</h1>
      <p className="text-sm w-3/5 text-center mt-5">
        {t('We’ll be in touch shortly.')}
      </p>
      <p className="text-xs w-8/12 text-center text-primary mt-8">
        {t('Your free trial starts once the account is approved.')}
      </p>
    </React.Fragment>
  )
}
