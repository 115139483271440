import {inject, observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Form, Input, PasswordInput, qs, Submit} from '@evet/library'
import {Validator} from 'rest/helpers/Validations'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {PolicyAndTerms} from 'components/extras'
import {StepWrapper} from '../../StepWrapper'
import {StepNavigator} from '../StepNavigator'

export const Step1 = inject(
  'AuthStore',
  'OfferStore'
)(
  observer(props => {
    const {
      name,
      family_name,
      email,
      password,
      phone: phone_number,
      company_url,
    } = new Validator()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {search} = useLocation()
    const params = qs.parse(search)
    const meta = props.OfferStore.meta
    const data = props.AuthStore.signupCache

    const schema = Yup.object().shape({
      name,
      family_name,
      email,
      password,
      phone_number,
      company_name: Yup.string().required(t('Required')),
      company_url,
      quote: Yup.string(),
    })

    const defaultValues = {
      // email: props.AuthStore.signupCache.email || '',
      ...data,
      plan: params.plan || data.plan || meta.defaultPlan,
    }

    // const handleReset = async _data => {
    //   props.AuthStore.signupCacheClear()
    // }

    const handleSubmit = async _data => {
      const {sessionID: guid} = props.AuthStore.signupCache
      if (await props.AuthStore.checkEmail({..._data, guid})) {
        props.AuthStore.signupCache = {...data, ..._data}
        await navigate(
          '/register/network/advertiser/promotion?' + qs.stringify(params)
        )
      }
    }

    const {resetSignupCache, authenticated} = props.AuthStore

    useEffect(() => {
      if (!data.sessionID || data._expire < new Date().getTime()) {
        resetSignupCache()
      }
    }, [resetSignupCache, data.sessionID, data._expire])

    useEffect(() => {
      ;(async () => {
        if (authenticated) {
          await navigate('/')
        }
      })()
    }, [navigate, authenticated])

    return (
      <StepWrapper
        page={t('ADVERTISER')}
        title={t('Become a Evet + Advertiser')}
        description={t(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Already a Evet + Publisher?  Login.'
        )}
      >
        <StepNavigator step={1} />

        <div className=" mt-30 per-30 t-per-70 m-per-90">
          <Form
            onSubmit={handleSubmit}
            schema={schema}
            defaultValues={defaultValues}
            className="per-100"
          >
            <Input label={t('First Name')} name="name" className="per-100 " />
            <Input
              label={t('Last Name')}
              name="family_name"
              className="per-100 mt-20"
            />
            <Input
              label={t('Email Address')}
              name="email"
              className="per-100 mt-20"
            />
            <PasswordInput
              label={t('Password')}
              name="password"
              className="per-100 mt-20"
            />
            <Input
              label={t('Business Name')}
              name="company_name"
              className="per-100 mt-20"
            />
            <Input
              label={t('Business Website URL')}
              name="company_url"
              className="per-100 mt-20"
            />
            <Input
              label={t('Phone Number')}
              name="phone_number"
              className="per-100 mt-20"
            />
            {defaultValues.plan === 'custom' && (
              <Input
                label={t(
                  'What type of search engine monitoring solution you need?'
                )}
                name="quote"
                className="per-100 mt-20"
                multiline
                rows={3}
              />
            )}
            <div className="steps mt-10 per-100 vertical center">
              <PolicyAndTerms />
            </div>
            <Submit className="sz-14 per-100 mt-20">{t('Continue')}</Submit>
          </Form>
        </div>
        <div className="horizon center middle per-100 mt-30 mb-60">
          <p className="f-300 sz-14">{t('Already have an account?')}</p>
          <Link
            className="basic-button f-400 sz-14 text-underline ml-6"
            to="/login"
          >
            {t('Sign in')}
          </Link>
        </div>
      </StepWrapper>
    )
  })
)
